import React, {memo, useState} from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import i18n from "../i18n/i18n";

const YouTube = ({youtubeid, height=300, start, style}) => {
  const [rerender, setRerender] = useState(false);
  if(window.isSchoolDomain) {
    return <div style={{padding: 20, lineHeight: 1.4}}>{i18n("youtube.js541386012")}</div>
  }
  const histomania_load_youtube = localStorage.getItem("histomania_load_youtube");
  const myStyle = {
        width: "100%",
        ...style,
        height: height,
  }
  return histomania_load_youtube ? <iframe title={"Video-"+youtubeid}
                                           style={myStyle}
                 height= {height}
                 src={"https://www.youtube.com/embed/" + youtubeid + "?" + (start ? ("&start=" + start) : "")}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
      />
    :
  <div style={{padding: 20}}>
        <FormControlLabel
            control={
              <Checkbox checked={false} onChange={() => {
                localStorage.setItem("histomania_load_youtube", true);
                setRerender(!rerender);
              }}
                        value="checkedA"/>
            }
            label={"Ich bin damit einverstanden, dass mir Inhalte von YouTube angezeigt werden. Hierdurch können personenbezogene Daten an Dritte übermittelt werden."}
        />
  </div>
}

export default memo(YouTube);
