import React, { useReducer, useState,  useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import LCalDateTimeField
  from "../ui-components/lib/datetimepicker/lcaldatetimefield";
import Paper from '@material-ui/core/Paper';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow, TableBody, Typography
} from "@material-ui/core";
import {LCal, LCalFormatter, LCalHelper} from "react-canvas-timeline";
import i18n from "../i18n/i18n";
import Popover from '@material-ui/core/Popover';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';

const useScroll = () => {
  const elRef = useRef(null);

  const executeScroll = () => {elRef.current && elRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })};

  return [executeScroll, elRef];
};

const formatLCal = (lcal) => {
  if(lcal) {
    //let type = LCalFormatter.formatType(lcal);
    let str = (lcal.precision > 10 ? LCalFormatter.formatDay(lcal)+". " : "") +  (lcal.precision > 9 ? (LCalFormatter.formatMonthNameL(lcal) + " ") : "") + LCalFormatter.formatYear(lcal) + (lcal.precision > 11 ? " " + LCalFormatter.formatTime(lcal) + " " + i18n("oClock"): "");
    return str;
  }
  return "andauernd";
}

const getLine = (item, mainEvent, forceUpdate, selectedItem, setSelectedItem, selectedColumn, setSelectedColumn, setPopoverAnchor, onDeleteEvent, onDetails, elRef) => {
  return <TableRow key={item.id} style={{background: item===mainEvent ? "green" : "inherit"}} ref={item===mainEvent ? elRef : null}>
          <TableCell onClick={(evt)=>{setPopoverAnchor(evt.currentTarget); setSelectedItem(item); setSelectedColumn(0)}} style={{maxWidth: 300}}>{item.name}</TableCell>
          <TableCell onClick={(evt)=>{setPopoverAnchor(evt.currentTarget); setSelectedItem(item); setSelectedColumn(3)}}>{formatLCal(item.start)}</TableCell>
          <TableCell onClick={(evt)=>{setPopoverAnchor(evt.currentTarget); setSelectedItem(item); setSelectedColumn(4)}}>{!item.isPointInTime() && formatLCal(item.end)}</TableCell>
          <TableCell style={{cursor: 'pointer'}} onClick={()=>{onDeleteEvent(item)}}><DeleteIcon/></TableCell>
          <TableCell style={{cursor: 'pointer'}} onClick={()=>{onDetails(item)}}><MoreIcon/></TableCell>
  </TableRow>

}

const MaintenanceEventListTable = ({model, onSaveEvents, onDeleteEvent, onEventDetails, mainEvent, resourceID}) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [executeScroll, elRef] = useScroll();
  useEffect(executeScroll);

  const filteredItems = model.getAll().filter(item => item.resID === resourceID);

  return <>
    {filteredItems.length > 0 && <Typography style={{marginBottom: 20}}>Klicke in die gewünschte Tabellenzelle zum Bearbeiten oder '...' um zu den Details zu gelangen.<br/>Jede Änderung wird sofort gespeichert, sobald du die Tabellenzelle verlässt.</Typography>}
    <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{i18n("maintenanceeventlisttable.js380608930")}</TableCell>
          <TableCell>{i18n("maintenanceeventlisttable.js866297000")}</TableCell>
          <TableCell>{i18n("maintenanceeventlisttable.js573824846")}</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredItems.map(item => getLine(item, mainEvent, forceUpdate, selectedItem, setSelectedItem, selectedColumn, setSelectedColumn, setPopoverAnchor, onDeleteEvent, onEventDetails, elRef))}
      </TableBody>
    </Table>
  </TableContainer>
    <Popover
        open={selectedItem !== null}
        anchorEl={popoverAnchor}
        onClose={()=>{setSelectedItem(null); onSaveEvents([selectedItem])}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{margin: 10}}
    >
    <Paper elevation={5} style={{padding: 10,  minWidth: Math.min(600, window.innerWidth-80)}}>
      {selectedItem && selectedColumn === 0 &&
                                    <TextField
                                      value={selectedItem.name}
                                      multiline
                                      onChange={(evt) => {selectedItem.name = evt.target.value; forceUpdate()}}
                                      fullWidth
                                      autoFocus
                                    />

      }
      {selectedItem && selectedColumn === 3 && <LCalDateTimeField value={selectedItem.start}
        onChange={(d) => {selectedItem.start = d}}
        yearPositions={12}
        showTypeField
        showPrecisionField
        />
      }
      {selectedItem && selectedColumn === 4 && <LCalDateTimeField value={selectedItem.end}
                                                  onChange={(d) => {if(d && selectedItem.start) {selectedItem.start.type = 580};selectedItem.end = d}}
                                                  yearPositions={12}
                                                  isEndType
                                                  showTypeField
                                                  showPrecisionField/>
      }
    </Paper>

    </Popover>
    </>
}

export default MaintenanceEventListTable;
