import React from "react";
import Typography from "@material-ui/core/Typography";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import Filter from "../images/icons/filter";

const res = {
  "newStory": "Нова історія",
  "newStoryTooltipHeader": "Створення нового матеріалу",
  "newStoryTooltipBody": "Створює рамки для вашої історії, часової шкали та подій.",
  "newEventTooltipHeader": "Як створити нову подію",
  "newEvent": "Нова подія",
  "newEventTooltipBody1": "Створити нову подію в часовій шкалі",
  "newEventTooltipBody2": "Створити подію через контекстне меню ",
  "newEventTooltipBody3": "(лівий клік на вільну позицію)",
  "newEventTooltipBody4": " у часовій шкалі, якщо ви хочете безпосередньо налаштувати час.",
  "search": "Пошук",
  "searchEventsAndStories": "Пошук подій та історій",
  "searchEventsAndStoriesTooltip1": "- Пошук подій у часовій шкалі під час введення",
  "searchEventsAndStoriesTooltip2": "- Пошук історій",
  "filter": "Фільтр", "filterEvents": "Фільтрувати події",
  "filterEventsTooltip1": "Показувати та приховувати події за різними критеріями",

  "measure": "Вимірювання", "measureTooltip": "Вимірювання часу у часовій шкалі",

  "help": "Допомога",

  "more": "більше…",

  "loginInfoNotLoggedIn": "Ви не увійшли в систему",
  "loginInfoDoLoginHere": "Увійти тут.",
  "login": "Увійти",
  "loginWithTwitter": "Увійти через X",
  "loginWithGoogle": "Увійти через Google",
  "loginWithFacebook": "Увійти через Facebook",
  "loginWithVIDIS": "Увійти через шкільний обліковий запис (VIDIS)",
  "loginWithFobizz": "Увійдіть за допомогою Fobizz",
  "loginWithEdulog": "Увійти через шкільний обліковий запис (edulog)",

  "storiesNearby": "Історії поблизу",
  "searchStories": "Пошук історій",
  "myStories": "Мої історії",
  "recentlySeen": "Нещодавно переглянуті",
  "usedImagesAndSources": "Використані зображення та їх джерела.",
  "sourcesOnlyGiven": "Джерела наводяться лише у випадку, якщо автор їх зазначив.",
  "imageSourceReferences": "Посилання на джерела зображень",


  "settings": "Налаштування",
  "separatorTimelineDesc": "Роздільник для часової шкали/опису",
  "horizontal": "горизонтальний",
  "vertical": "вертикальний",
  "showSidewaysDuringEventSelection": "показувати збоку під час вибору події",
  "alwaysSuperimpose": "завжди накладати",
  "automatic": "автоматично",
  "detailView": "Детальний перегляд",
  "eventLabeling": "Маркування подій",
  "fullLabeling": "повне маркування",
  "maxAsLongAsBar": "максимально довге, як і полоса події",
  "showTimelineBackgroundImage": "Фонове зображення часової шкали",
  "showTimelineBackgroundImageTrue": "показати",
  "showTimelineBackgroundImageFalse": "приховати",
  "timelineTheme": "Тема часової шкали",
  "dark": "темна",
  "bright": "світла",
  "chooseStory": "Вибрати історію",

  "close": "Закрити",
  "save": "Зберегти",
  "ok": "Гаразд",
  "cancel": "Скасувати",
  "yes": "Так",
  "no": "Ні",
  "delete": "Видалити",
  "copy": "Копіювати",
  "edit": "Редагувати",
  "back": "Назад",
  "understoodAndSave": "Зрозуміло та зберегти",

  "error": "Помилка",

  "errorInternal": () => (<>Сталася невідома внутрішня помилка.<br/>Будь ласка, зв’яжіться з представником histomania.</>),
  "errorMailOrPwdNotFound": (fatLink, showRequestNewPassword) => (<>Ми не знаємо вашу електронну адресу або пароль, який ви ввели.<br/><span style={fatLink} onClick={showRequestNewPassword}>Якщо ви забули свій пароль, будь ласка, замовте новий тут.</span>.</>),
  "msgPasswordSent": () => (<>Ми надіслали вам електронного листа з посиланням для підтвердження.<br/>Будь ласка, натисніть на це посилання, щоб ми могли надіслати вам новий пароль.</>),
  "msgNotFound": () => (<>Запитувана подія не знайдена.<br/>Будь ласка, зв'яжіться з контактною особою з питань гістоманії.</>),
  "errorNetwork": () => (<>Виникла помилка.<br/>Будь ласка, переконайтеся, що з'єднання з Інтернетом працює.</>),
  "msgAccountCreated": () => (<>Ваш обліковий запис створено.<br/>Ми надіслали вам дані для входу на електронну пошту.<br/>Також перевірте папку "Спам", якщо це необхідно.</>),
  "errorDuplicateUserEntry": () => (<>Ми не змогли відтворити ваш обліковий запис, оскільки користувач вже був у нашій базі даних.<br/>Будь ласка, увійдіть, використовуючи існуючі дані або отримайте новий пароль, надісланий вам у розділі "Забули пароль".</>),
  "errorUserNotFound": () => (<>Користувач не існує.</>),
  "errorUnknown": () => (<>Сталася невідома помилка.<br/>Зверніться, будь ласка, до представника histomania.</>),
  "titleNewPasswordRequested" : "Запитано новий пароль",
  "titleAccountCreated" : "Створення облікового запису",
  "or" : "або",
  "createAccount" : "Створіть свій новий обліковий запис на histomania тут безкоштовно, заповнивши лише кілька даних",
  "exportToExcel" : "Експорт даних до MS-Excel",
  "addToWebsite" : "Додайте hiStories, які зараз відображаються, на свій сайт, вставивши наведений нижче код.",
  "codeMustBeInBodyTag" : "Код повинен бути всередині тегу body вашого HTML-документа.",
  "embedAcceptAGB" : "Вбудовуючи контент histomania у свій веб-сайт або додаток, ви приймаєте Загальні положення та умови ведення бізнесу та використання histomania.",
  "permissions" : "Дозволи",
  "profile" : "Профіль",
  "firstName" : "Ім'я та прізвище",
  "lastName" : "Прізвище",
  "inputFirstName" : "Будь ласка, введіть своє ім'я",
  "inputSurName" : "Будь ласка, введіть своє прізвище",
  "newsLetterInfo" : "Інформаційний бюлетень виходить приблизно 5-6 разів на рік та інформує вас про інновації та вибрані hiStories.",
  "subscribeNewsletter" : "Підпишіться на розсилку",
  "loading" : "завантажити",
  "changePassword" : "Зміна пароля",
  "pwdChangeSuccessful" : "Ваш пароль успішно змінено.",
  "pwdChangeErr" : "Помилка при зміні пароля.",
  "pwdLength" : "Обраний пароль повинен містити щонайменше 8 символів.",
  "pwdRepetitionError" : "Повторний пароль відрізняється від першого введеного.",
  "pwdChange" : "Змінити пароль",
  "pwdOld" : "Старий пароль",
  "pwdNew" : "Новий пароль (мінімум 8 символів)",
  "pwdNewRepetition" : "Новий пароль (повторити)",
  "showWikipediaTooltip" : "Показати статтю у Вікіпедії",
  "whatIf" : "А якщо...",
  "changeTime" : "Час зміни",
  "moveTimelineFromRefDate" : "Переміщення часової шкали від однієї базової дати до іншої:",
  "moveTimelineByDuration" : "Або відкласти на певний час:",
  "noteTimelineIsNotSaved" : "Примітка: змінена шкала часу не зберігається.",
  "moveMeasuringStick" : "Перемістіть вимірювач на вказаний час",
  "moveMeasuringStickBy" : "Перемістіть супорт ближче:",
  "moveMeasuringStickTo" : "Перемістіть супорт на:",
  "toPast" : "в минуле",
  "toFuture" : "у майбутнє",
  "imageWithLicenseImg" : "***Картинка:***",
  "dragNdropHint" : "Перетягніть файл або клацніть, щоб додати зображення",
  "maxFileSize" : "Максимальний розмір файлу:",
  "info" : "Інформація",
  "attention" : "Увага!",
  "articleOnWikipedia" : "Стаття у Вікіпедії",
  "wikidataRecord" : "Запис у Вікіданих",
  "showLocationOnGoogleMaps" : "Показати місцезнаходження на Картах Google",
  "showStoriesNearby" : "Більше hiStories поруч з вами",
  "showStoriesAtThatTime" : "Що ще відбувалося в той час?",
  "showVideosOnYoutube" : "Відео на YouTube",
  "showBooksOnAmazon" : "Книги на Amazon",
  "showDetails" : "Показати деталі",
  "details" : "Деталі",
  "now" : "Зараз.",
  "closeWithSave" : "Зберегти перед закриттям?",
  "closeWithoutSaveQuestion" : () => (<>Натисніть "Так" і збережіть зміни.<br/>Інакше змінені дані будуть втрачені.</>),
  "seconds" : "Секунди.",
  "minutes" : "Протокол",
  "hours" : "Години",
  "days" : "Дні",
  "untilNow" : "до цього часу.",
  "secondsUntilNow" : "Секунди до цього моменту",
  "minutesUntilNow" : "Хвилини до цього моменту",
  "hoursUntilNow" : "Години до цього часу",
  "daysUntilNow" : "Днів до сьогодні",
  "unknownDuration" : "невизначений період",
  "oClock" : "Годинник",
  "pastOrFutureDuration" : (isPast, duration) => ((isPast ? " тому"  : "через" ) + duration),
  "startType" : "Тип запуску",
  "endType" : "Тип торця",
  "pointInTime" : "Час",
  "born" : "народився",
  "earliest" : "найраніше",
  "start" : "Старт",
  "origin": () => (<span><b>Походження</b><br/><Typography variant="caption">або заснування/створення</Typography></span>),
  "discovery" : "Відкриття",
  "publication" : "Публікація",
  "firstDescription" : "Перший опис",
  "baptized" : "охрещений",
  "premiere" : "Прем'єра",
  "opened" : "відкривається",
  "activeSince" : "активний з того часу, як",
  "died" : "померла.",
  "latest" : "найпізніше",
  "end" : "Кінець",
  "decommissioning" : "Виведення з експлуатації",
  "dissolved" : "Розпущено",
  "lost" : "пропав безвісти з тих пір, як",
  "activeUntil" : "активний до тих пір, поки",
  "discontinued" : "набір",
  "closed" : "закрито",
  "precision" : "Точність",
  "1000000000years" : "1 мільярд років",
  "100000000years" : "100 мільйонів років",
  "10000000years" : "10 мільйонів років",
  "1000000years" : "1 мільйон років",
  "100000years" : "100 тисяч років",
  "10000years" : "10 тисяч років",
  "millenium" : "Міленіум",
  "century" : "Століття",
  "decade" : "Десятиліття",
  "year" : "Рік",
  "month" : "Місяць",
  "day" : "День",
  "hour" : "Година.",
  "minute" : "Хвилинку.",
  "exact" : "Точнісінько.",
  "duration" : "Тривалість",
  "years" : "Роки",
  "mon." : "Мон.",
  "h." : "ЗПСШ.",
  "min." : "Мін.",
  "noTimeChosen" : "Не вибрано дату",
  "today" : "сьогодні",
  "weekdays": ()=>(["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]),
  "internalServerError": ()=>(<div>Сталася внутрішня помилка сервера.<br/>Будь ласка, зв'яжіться з контактною особою в histomania.</div>),
  "userNotAcknowledged": ()=>(<div>Користувач існує в нашій системі, але ще не був підтверджений вами за посиланням в електронному листі.</div>),
  "userOrPwdNotInSystem": ()=>(<div>Ми не знаємо ні користувача, ні введеного пароля.</div>),
  "oldPasswordUnknown": ()=>(<div>Ми не знаємо старого пароля.</div>),
  "pwdGuideline": ()=>(<div>Введені дані не відповідають рекомендаціям.</div>),
  "noDataRefresh": ()=>(<div>Дані не вдалося оновити.</div>),
  "emailAlreadyAvailable": ()=>(<div>Введена адреса електронної пошти вже існує</div>),
  "userNotCreated": ()=>(<div>Не вдалося створити користувача</div>),
  "reCaptchaError": ()=>(<div>Google reCaptcha: Вас не вдалося ідентифікувати як люди</div>),
  "notPlausible": ()=> (<div>Вхідні дані не є правдоподібними.</div>),
  "sessionInvalid": ()=>(<div>Сесія більше не дійсна.<br/>Будь ласка, зареєструйтесь ще раз.</div>),
  "unknownError": (errorCode)=>(<div>Виникла невідома помилка з номером {errorCode}.<br/>Будь ласка, зв'яжіться з контактною особою в histomania.</div>),
  "hideStory": ()=>(<><Typography color="inherit">hiStory приховати</Typography><hr/>Ви можете швидко знайти цю історію після того, як сховали її, скориставшись пошуком у розділі  <br/><em>'нещодавно переглянуті'</em>.</>),
  "showStory": ()=>(<><Typography color="inherit">Показати hiStory</Typography><hr/>Відкрийте hiStory на новій вкладці і додайте часову шкалу паралельно до існуючих часових шкал.</>),
  "showStory2" : "Показати історію",
  "restrictYourSearch": (totalcnt)=>("Занадто багато результатів (" + totalcnt + "). Будь ласка, звузьте пошуковий запит ще більше."),
  "allowGeoLocation" : "Будь ласка, спочатку дозвольте histomania отримати доступ до вашого місцезнаходження в налаштуваннях.",
  "everytime" : "в будь-який час.",
  "everywhere" : "скрізь",
  "myNeighbourhood" : "поруч зі мною.",
  "inNeighbourhood" : "біля",
  "search2" : "Пошук",
  "currentView": ()=>(<>поточний<br/>вигляд</>),
  "recentlySeen2": ()=>(<>бачив<br/>нещодавно</>),
  "myStories2": ()=>(<>мої<br/>hiStories</>),
  "searchPhrase" : "Пошукові терміни",
  "inputSearchPhrase" : "Введіть пошуковий запит",
  "searchPhraseSample" : "наприклад: Фізик Альберт",
  "minimumCharacters": (minSearchWordCharCnt)=>("Щонайменше " + minSearchWordCharCnt + " символів на пошуковий запит"),
  "when" : "Період проведення заходу",
  "where" : "Місцезнаходження",
  "inNeighbourhoodOf" : "біля...",
  "chosenPosition" : "вибрана позиція",
  "latitude" : "Широта",
  "longitude" : "Довгота",
  "searchTimelines" : "Пошук hiStories",
  "searchStoriesInNeighbourhood" : "Шукайте hiStories поруч з вами",
  "resultsFromLocalSearch" : "Події з локального пошуку (натисніть, щоб дізнатися більше):",
  "recommended" : "Рекомендовані історії",
  "todaysBirthdays" : "Іменинники сьогодні",
  "todaysDeathdays" : "Роковини смерті сьогодні",
  "anniversaries" : "Річниці подій сьогодні",
  "hideAll" : "Приховати все",
  "inCurrentView" : "в поточному перегляді",
  "privateTimeline" : "приватна історія",
  "less" : "менше...",
  "image" : "Зображення",
  "logoURL": "https://"+window.location.host,
  "wikiLicenseCCSA": (site) => (<>джерело: en.wikipedia.org - Текст доступний за ліцензією <a target="_blank" rel="noopener noreferrer" href={"https://en.wikipedia.org/wiki/Wikipedia:Text_of_Creative_Commons_Attribution-ShareAlike_3.0_Unported_License"}>"Creative Commons Attribution/Share Alike"</a> licence.<br/>Тут ви можете знайти список <a target="_blank" rel="noopener noreferrer" href={"https://xtools.wmflabs.org/articleinfo-authorship/en.wikipedia.org/"+site+"?uselang=en"}>авторів</a>.</>),
  "wikiURL": "https://en.wikipedia.org/wiki/",
  "infiniteCalendarHeaderFormat" : "dddd, D MMM",
  "resetfilter" : "Скинути фільтр",
  "maintainEventList" : "Ведення подій у табличній формі",
  "importcsv" : "Імпорт з CSV",
  "import" : "Імпорт",
  "prenameError" : "Будь ласка, введіть своє ім'я без цифр та спеціальних символів. Ім'я повинно складатися щонайменше з двох символів.",
  "surnameError" : "Будь ласка, введіть своє прізвище без цифр та спеціальних символів. Прізвище повинно складатися щонайменше з двох символів.",
  "mailError" : "Будь ласка, введіть дійсну адресу електронної пошти, щоб ми могли надіслати вам посилання для підтвердження.",
  "fonts" : "Шрифти...",
  "bold" : "сміливий",
  "italic" : "курсив",
  "underline" : "підкреслено",
  "code" : "Код",
  "unorderedList" : "невпорядкований список",
  "orderedList" : "впорядкований список",
  "quote" : "Цитата",
  "codeblock" : "Кодовий блок",
  "divider" : "Горизонтальний дефіс",
  "alignment" : "Вирівнювання тексту...",
  "addImage" : "Додати зображення",
  "addVideo" : "Додати відео",
  "addMap" : "Додати карту",
  "addPanorama" : "Додати огляд на 360°",
  "addQuiz" : "Додати вікторину",
  "addInfoblock" : "Додати інформаційний блок",
  "addLink" : "Зв'яжіть позначений текст з веб-сайтом",
  "addEvent" : "Зв'яжіть позначений текст з подією на шкалі часу",
  "addStory" : "Зв'яжіть розмічений текст з hiStory",
  "addFilter" : "Зв'язати позначений текст з фільтром",
  "landingpage_title" : "Платформа для історії на часовій шкалі",
  "welcome.js735054413": "Відкрийте для себе світ історії в hiStories, інтерактивній та мультимедійній збірці хронологій, текстів, зображень, відео, вікторин, 360° переглядів та карт.",
  "welcome.js142706916": "Створюйте власні хронології для вашого резюме, місцевої хроніки або навчальної підтримки для незабутнього досвіду.",
  "welcome.js737110502": "моє оточення",
  "welcome.js455445377": "рансак",
  "welcome.js695746560": "Відкрийте для себе світ історії в hiStories, інтерактивній та мультимедійній збірці хронологій, текстів, зображень та вікторин.",
  "welcome.js152384233": "Створюйте зі своїми однокласниками або студентами-колегами власні хронології та вчіться розуміти історичні процеси.",
  "explanation" : () => (<>
    Відчуйте історію по-новому з histomania: інтерактивною платформою, яка дозволяє легко розповідати та розуміти історії.
    <br/>
    <br/>
    Не втрачайте з виду час та місце, переглядаючи тисячі історій та біографій.
    <br/>
    На відміну від класичних гіпертекстових оповідань, де можна лише переходити з однієї статті на іншу, тексти у histomania містять додаткові посилання, які дозволяють слідкувати за подіями на часовій шкалі або карті.
    <br/>
    <br/>
    <PostAddIcon/>
    відкриває нову hiStory
    <br/>
    <ScheduleIcon/>
    показує подію на часовій шкалі
    <br/>
    <RoomIcon/>
    показує положення події на карті
    <br/>
    <Filter/>
    фільтрує події на часовій шкалі та хронології
    <br/>
    <br/>
    Досліджуйте порядки величин часових періодів, порівнюючи часові шкали різних hiStories та вимірюючи відстань між подіями у цих шкалах.
    <br/>
    Порівнюючи та вимірюючи, ви зможете розкрити часові періоди та епохи історії по-новому.
    <br/>
    <br/>
    Використовуйте інтерактивні карти, які можуть містити не лише позначки для позначення позиц≥й, але й ланцюжки стр≥лок для маршрут≥в подорожей та накладки для в≥дображення областей.
    <br/>
    Ц≥ нав≥ть можуть бути ан≥мован≥ та мати р≥вень прозорост≥, обраний вами, що дасть вам ун≥кальне розум≥ння зм≥н кордон≥в.
    <br/>
    <br/>
    Виявляйте істор≥ї у вашому поточному оточенн≥ або п≥д час подорож≥ у чужому м≥ст≥ одним натисканням кнопки. Знаходьте потр≥бн≥ ≥стор≥ї у музеях за допомогою QR-коду.
    <br/>
    <br/>
    Ви нав≥ть можете вбудувати свої улюблен≥ hiStories у свою власну домашню стор≥нку.
    <br/>
    <br/>
    Незалежно в≥д того, чи хочете ви публ≥кувати свої власн≥ hiStories або просто серфити ≥стор≥єю, histomania - це ≥деальний супутник для учн≥в на уроках ≥стор≥ї, студент≥в ≥стор≥ї, ≥сторик≥в, м≥сцевих хрон≥ст≥в та вс≥х, хто просто любить ≥стор≥ю або поточн≥ под≥ї у пол≥тиц≥ та св≥тових справах.
  </>),
  "topfacts_wiki": () => (<span>Чи є відповідна стаття у Вікіпедії або відео на YouTube для цієї історії або події в історії?<br/>Або, якщо подія локалізована, чи хотіли б ви переглянути її на карті Google Maps, щоб вона провела вас туди?<br/>Один клік - і ви переходите від подробиць в гістоманії до відповідної сторінки.</span>),
  "topfacts.js415020171" : "серфінг",
  "topfacts.js111683501" : "порівнювати",
  "topfacts.js56475656" : "інтегрувати",
  "topfacts.js939157228" : "безкоштовно",
  "topfacts.js617723463" : "міра",
  "topfacts.js692398414" : "створювати",
  "topfacts.js899063949" : "вигадані запитання",
  "topfacts.js152860411" : "частка",
  "topfacts.js55830277" : "пов'язаний",
  "topfacts.js335278155" : "пошук",
  "topfacts.js150418448" : "Незалежність від пристрою",
  "topfacts.js820645511" : "від великого вибуху до кінця світу",
  "topfacts.js645936163" : "Пов'язуючи історичні події, ви можете переходити від однієї історії до іншої.",
  "topfacts.js751322491" : "histomania дозволяє розмістити кілька часових променів між собою і таким чином зробити їх порівнянними.",
  "topfacts.js921529656" : "Хочете вбудувати історії на власний сайт чи блог? Дізнайтеся більше тут.",
  "topfacts.js895080977" : "Базова версія histomania є безкоштовною. Незалежно від того, чи використовуєте ви її в особистих, комерційних або освітніх цілях.",
  "topfacts.js948823105" : "На скільки років Мерилін Монро старша за Джеймса Діна? Скільки років було Ейнштейну, коли він отримав Нобелівську премію? Просто виміряйте час, який вас цікавить, на часовій шкалі за допомогою вбудованого штангенциркуля.",
  "topfacts.js107256625" : "Напишіть власну історію. Викладіть історичні події так, щоб показати те, що важливо для вас. Або опишіть своє життя на часовій шкалі.",
  "topfacts.js102762366" : "Що, якби... ви народилися в той самий час, що й Гете, наприклад? Створіть свою біографію і перемістіть її так, щоб ви могли порівняти її з біографіями інших.",
  "topfacts.js373990748" : "Працюйте разом з іншими над хронологією і використовуйте дозволи, щоб вирішити, хто може бачити або редагувати вашу історію.",
  "topfacts.js809530473" : "Окрім пошуку за ключовими словами, ви також можете шукати, що відбувалося в певних місцях у певні періоди часу. Ви перебуваєте в незнайомому місці і хочете дізнатися більше про історію цієї місцевості? Натисніть на \"Що поблизу\" і шукайте події в околицях за допомогою смартфона з підтримкою GPS.",
  "topfacts.js15371141" : "На смартфоні, планшеті чи ПК. З histomania ви отримуєте додаток, який адаптується до всіх пристроїв, незалежно від розміру екрану або типу введення за допомогою миші чи сенсорних жестів.",
  "topfacts.js678201177" : "Істоманія може обробляти неймовірно великі проміжки часу з точністю до хвилини. Таким чином, можуть бути змістовно представлені як епохи історії Землі, так і історія першої висадки на Місяць.",
  "contact": "контакт",
  "phone": "Телефон",
  "newHiStoryMessage" : ()=> (<span>Ваша нова hiStory створена.<br/>Тепер додайте події.</span>),
  "histomaniamain.js218992075" : "Будь ласка, вкажіть принаймні одного адміністратора.",
  "menu.js844059924" : "Нова історія",
  "storydetailsmain.js785302558" : "нова hiStory",
  "storydetailsmain.js382589702": "hiStory",
  "mainFab.js309680386" : "Редагувати hiStory",
  "mainFab.js593675655" : "Ведення подій у таблиці",
  "mainFab.js968262525" : "Додати подію",
  "mainFab.js943257132" : "new hiStory",
  "bookmarks.js93045028" : "Додати в закладки",
  "menu.js505082777" : "Нова подія",
  "menu.js756551371" : "А якщо...?",
  "menu.js663038438" : "Що, якби... Відмінити",
  "menu.js439620799" : "Додати в закладки",
  "menu.js157014000" : "Друк та експорт зображень",
  "menu.js555525792" : "Імпорт",
  "menu.js923812869" : "Керування профілем",
  "menu.js450602741" : "Мій профіль",
  "menu.js984392851" : "Змінити пароль",
  "menu.js498764961" : "Мова",
  "menu.js869701443" : "Німецька",
  "menu.js503793771" : "Англійська мова",
  "menu.js161223496" : "Українська",
  "menu.js961199351" : "Мої налаштування",
  "menu.js165576261" : "Вийдіть з системи",
  "menu.js972296370" : "Увійдіть в систему",
  "bargroup.js313011152" : "Призначення",
  "bargroup.js999078620" : "нове позначення",
  "barshape.js677799618" : "жирний батончик",
  "barshape.js210728036" : "тонка смужка",
  "barshape.js708427194" : "фігурна дужка",
  "barshape.js853386386" : "прозорий фон",
  "barshape.js709767519" : "маленька зірка",
  "barshape.js620435244" : "Зірка",
  "barshape.js170641551" : "мале коло",
  "barshape.js649456610" : "Коло",
  "barshape.js494726977" : "Хмара",
  "barshape.js735933567" : "Мовна бульбашка",
  "barshape.js931763133" : "Текст посередині",
  "barshape.js931763134" : "тільки базовий рівень",
  "barshape.js509994468" : "невеликий документ",
  "barshape.js848987645" : "Документ",
  "barshape.js786118528" : "маленьке сонце",
  "barshape.js560723628" : "Сонце",
  "barshape.js624588809" : "маленький хрест",
  "barshape.js33818872" : "Хрест",
  "barshape.js597772577" : "маленька стрілка вліво",
  "barshape.js170062957" : "Стрілка вліво",
  "barshape.js731660847" : "маленька стрілка вправо",
  "barshape.js233359132" : "Стрілка вправо.",
  "dataset.js729839193" : "Помилка в описі",
  "dataset.js345733057" : "Призначення",
  "dataset.js850812704" : "Одиниця",
  "descriptionBlock.js240405443" : "Опис",
  "descriptionBlock.js884402762" : "Ви також можете вставляти сюди фотографії та відео.",
  "designBlock.js426292766" : "Дизайн",
  "designBlock.js902377753" : "Так подія з'являється на часовій шкалі.",
  "imageBlock.js428756050" : "Зображення",
  "imageBlock.js604367201" : "Це зображення з'являється на часовій шкалі. Ви можете додати більше зображень в описі.",
  "imageBlock.js399000806" : "Ліцензія на зображення",
  "imagechooser.js38403923" : "Ліцензія на зображення",
  "innerevents.js238036015" : "Внутрішній час",
  "sourceReferenceBlock.js99152413" : "Посилання",
  "sourceReferenceBlock.js484508229" : "Вкажіть тут джерело (джерела), що підтверджують цю подію.",
  "timeBlock.js186865327" : "Час",
  "timeBlock.js929675741" : "Для дохристиянських часів поставте мінус перед роком, наприклад, 21.04.-753.",
  "timeBlock.js910792715" : "Час",
  "timeBlock.js134397445" : "Крапка",
  "titleBlock.js239194644" : "Назва",
  "titleBlock.js47802846" : "Ви можете використовувати кілька рядків.",
  "wikipediareference.js851003152" : "Німецька сторінка Вікіпедії",
  "bookmarkitem.js608408822" : "Видалити",
  "story.js915248213" : "Моя історія",
  "drafteditortoolbar.js858681026" : "Шрифт",
  "drafteditortoolbar.js823346092" : "Абзац",
  "drafteditortoolbar.js97337207" : "Медіа",
  "drafteditortoolbar.js82711167" : "Посилання",
  "chooseImageDialog.js542504966" : "Назва",
  "chooseMapDialog.js901660970" : "Позначення карти",
  "chooseMapDialog.js589922616" : "Призначення",
  "chooseMapDialog.js87695793" : "URL-адреса Google Maps",
  "chooseMapDialog.js10384243" : "Опис зображення та ліцензія",
  "chooseStreetViewDialog.js854616579" : "Назва",
  "chooseStreetViewDialog.js119725327" : "Код вбудовування Streetview",
  "chooseVideoDialog.js778434054" : "Назва",
  "chooseVideoDialog.js671606307" : "URL-адреса або ідентифікатор YouTube",
  "chooseVideoDialog.js799772333" : "Почніть з другої",
  "AddVideoButton.js665672521" : "Створіть часову шкалу безкоштовно",
  "maintenanceEventList.js998639033" : "Події",
  "filtertimeline.js710501750" : "Фільтрувати події",
  "searchfield.js825023426" : "Шукайте hiStories за цим терміном",
  "searchfield.js18900516" : "привіт Історії в моєму районі",
  "searchfield.js363834300" : "Шукайте hiStories за часом та місцем",
  "searchfield.js644144191" : "Мої історії",
  "searchfield.js660560315" : "Нещодавно переглянуті хісторії",
  "searchfield.js961730789" : "Відфільтрувати поточну шкалу часу за цим терміном",
  "searchfield.js867502466" : "Фільтрувати поточну шкалу часу",
  "searchfield.js829902906" : "Пропозиції щодо пошуку",
  "histomaniamain.js781485964" : "Моя подія",
  "histomaniamain.js702299662" : "Будь ласка, спочатку відкрийте або створіть hiStory, щоб створити подію.",
  "histomaniamain.js197615179" : "Будьте обережні, коли ділитеся своєю URL-адресою.\nВаша hiStory є приватною і її не можуть бачити всі одержувачі.",
  "histomaniamain.js12025145" : "Будь ласка, придумайте назву, що складається щонайменше з трьох літер для вашої hiStory.",
  "histomaniamain.js310062970" : "Щоб зберегти hiStory на довший час, вам потрібно увійти в систему.",
  "histomaniamain.js5248846" : "Будь ласка, спочатку відкрийте hiStory, яку ви маєте право редагувати, щоб скопіювати туди подію.",
  "histomaniamain.js513585760" : "перейти до",
  "import.js261569334" : "Будь ласка, спочатку відкрийте hiStory, щоб записати туди події.",
  "import.js67411235" : "Виберіть hiStory, до якої потрібно записати події.",
  "login.js758426829" : "Увійдіть в систему",
  "login.js779088926" : "Електронна пошта",
  "login.js163307733" : "Пароль",
  "login.js293038552" : "Залишайтеся в системі",
  "login.js116720706" : "Ще немає рахунку?",
  "login.js319559879" : "Ім'я та прізвище",
  "login.js278946069" : "Будь ласка, введіть ім'я",
  "login.js897560837" : "Прізвище",
  "login.js237687098" : "Будь ласка, введіть прізвище",
  "login.js457756656" : "Електронна пошта",
  "login.js692708124" : "Будь ласка, введіть дійсну адресу електронної пошти",
  "login.js368777066" : "Забули пароль?",
  "login.js327950210" : "Електронна пошта",
  "login.js493155713" : "Реєстрація",
  "editMediaDialog.js254758074" : "Список ЗМІ",
  "editMediaDialog.js106456795" : "JSON",
  "markdowneditfield.js110445871" : "Редагувати панорамний вид",
  "markdowneditfield.js117272661" : "Тест на редагування",
  "markdowneditfield.js848013488" : "Редагувати медіаелемент",
  "lazygooglemap.js947359452" : "Я погоджуюсь, що мені може бути показаний контент з Карт Google. Це може призвести до передачі персональних даних третім особам.",
  "QuizEditorTitlePage.js823814415" : "Назва",
  "menu.js893403567" : "Поділіться",
  "menu.js932778276" : "як електронну пошту",
  "menu.js556545983" : "Експорт даних",
  "menu.js413767225" : "Імпорт даних",
  "menu.js990897862" : "Допоможіть!",
  "menu.js171939370" : "від ",
  "print.js570625276" : "Мінімальна ширина становить",
  "print.js612051645" : "Максимальна ширина становить",
  "print.js574630162" : "Мінімальна висота променя становить",
  "print.js760791437" : "Максимальна висота променя становить",
  "print.js758964558" : "Перевищено максимальний звис.",
  "print.js375853243" : "Виступ не повинен бути негативним.",
  "print.js15990392" : "Повинна бути хоча б одна горизонтальна частина для друку.",
  "print.js556329669" : "Повинна бути хоча б одна вертикальна частина для друку.",
  "print.js833055774" : "Зображення не повинно бути розрізане більш ніж на 100 частин.",
  "printdialog.js85755474" : "Звисання через маркування (",
  "printdialog.js341020472" : "прозорий фон",
  "printdialog.js665169354" : "Малюємо тіні",
  "printdialog.js605134089" : "горизонтальний",
  "printdialog.js839026585" : "вертикальний",
  "displaystorydetails.js158045992" : "Джерела",
  "addstorydescriptor.js92624992" : "Вбудовані hiStories",
  "addstorydescriptoreditdialog.js502574362" : "Група",
  "addstorydescriptoreditdialog.js124818429" : "Ідентифікатор hiStory",
  "addstorydescriptoreditdialog.js117860561" : "Нефільтровані ідентифікатори подій",
  "addstorydescriptoreditdialog.js777122119" : "Посада",
  "backgroundimageinput.js816937029" : "Зображення",
  "backgroundimageinput.js218259721" : "Це зображення слугує фоном часової шкали і відображається в пошуку. Ви можете додати більше зображень в описі.",
  "backgroundimageinput.js429573179" : "Ліцензія на зображення",
  "userrightsdialog.js70424287" : "Новий дозвіл",
  "userrightsdialog.js480394996" : "Електронна пошта",
  "userrightsdialog.js216158241" : "Будь ласка, введіть дійсну адресу електронної пошти",
  "bookingrightsadministration.js589033383" : "Дозволи",
  "bookingrightsadministration.js546709873" : "кожен може прочитати hiStory",
  "descriptioninput.js517543907" : "Опис",
  "dummyuserhint.js499234330" : "Ви не ввійшли в систему",
  "headlineinput.js150642075" : "Заголовок",
  "headlineinput.js593049979" : "Назва історії",
  "headlineinput.js477282705" : "Субтитри",
  "newstoryhint.js799008560" : "Перші кроки",
  "sourcereferenceinput.js783166353" : "Посилання",
  "wikipediainput.js705594377" : "Розширений зміст",
  "search.js726564732" : "Пошук",
  "search.js671154469" : "в будь-який час.",
  "durationpanel.js274332505" : "невизначений період",
  "searchfield.js807364549" : "Пошук у поточній шкалі часу",
  "histomaniamain.js734544182" : "hiStory зберігається лише протягом вашого сеансу.",
  "menu.js698756284" : "Будь-які зміни, зроблені до цього часу на тестовій основі, скидаються.",
  "userrightsdialog.js937814759" : "Авторизація для редагування",
  "bargroup.js45524065" : "Скрізь змініть позначення",
  "sourceReferenceBlock.js304249147" : "Вказуйте джерела для події.",
  "timeBlock.js20893151" : "Внутрішні часи...",
  "wikipediareference.js907588328" : "Я хотів би вставити статтю з Вікіпедії.",
  "bookmarkdialog.js412170740" : "Додати закладку",
  "bookmarkdialog.js929413458" : "Ви не ввійшли в систему. Ваші закладки будуть дійсні лише протягом одного дня.",
  "bookmarks.js475765892" : "Зберігайте відкриті часові шкали як закладки;",
  "bookmarks.js306859711" : "Справді видалити?",
  "cookieconsent.js905029185" : "Ми використовуємо файли cookie та записи в localStorage. Ви можете будь-коли змінити свої налаштування за допомогою меню в додатку. Якщо ви не бажаєте приймати всі файли cookie або записи localStorage або хочете дізнатися більше про них, натисніть \"Показати більше\".",
  "cookieconsent.js352836975" : "Показати більше",
  "cookieconsent.js243259253" : "Закрити",
  "cookieconsent.js645797755" : "Прийняти всі файли cookie",
  "schoolcookieconsent.js905029185": () => <>Ми використовуємо на schule.histomania.com лише технічно необхідні файли cookie та записи localStorage, які неможливо відхилити.<br/>До них відносяться сесійні файли cookie та записи localStorage, які зберігають вибір мови, ідентифікатори користувача, щоб йому не довелося входити в систему повторно, а також підтвердження цього вікна.<br/></>,
  "drafteditortoolbar.js747935826" : "Шрифт",
  "drafteditortoolbar.js616605922" : "Абзац",
  "drafteditortoolbar.js894699961" : "Медіа",
  "drafteditortoolbar.js109669568" : "Посилання",
  "chooseMapDialog.js417538297" : "Крок другий:",
  "chooseMapDialog.js561133791" : "Крок 3:",
  "chooseMapDialog.js61491380" : "взяти на себе",
  "Link.js291014714" : "Відкрити hiStory",
  "Link.js62298905" : "Фільтрувати події на шкалі часу",
  "maintenanceeventlisttable.js380608930" : "Призначення",
  "maintenanceeventlisttable.js866297000" : "Старт",
  "maintenanceeventlisttable.js573824846" : "Кінець",
  "filtertasklist.js930196063" : "Усе.",
  "filtertasklist.js18265885" : "Всі події",
  "filtertasklist.js560877919" : "Термін",
  "filtertasklist.js132146692" : "Крапка",
  "filtertasklist.js8240783" : "Властивості",
  "filtertasklist.js192609356" : "Події в часі",
  "filtertasklist.js774361819" : "Події періоду",
  "filtertasklist.js700792519" : "Групи",
  "filtertasklist.js482827431" : "Окремі події",
  "extendedmenu.js908817077" : "Друк та експорт зображень",
  "extendedmenu.js336969410" : "Додати в закладки",
  "extendedmenu.js82030918" : "Налаштування",
  "extendedmenu.js7002811" : "Допоможіть!",
  "extendedmenu.js598943965" : "Мова",
  "extendedmenu.js760560118" : "Головна",
  "histomaniamain.js114097156" : "Повна часова шкала у видимій області",
  "import.js191606136" : "Імпорт CSV",
  "import.js112638704" : "Скопіюйте вміст вашого CSV-файлу в текстове поле і натисніть \"Імпортувати\".",
  "login.js382006560" : "Підпишіться на розсилку новин histomania.",
  "login.js416865926" : "Умови використання",
  "login.js64439934" : "Політика конфіденційності",
  "login.js572661779" : "Я прочитав і приймаю умови використання та політику конфіденційності",
  "login.js230957075" : "Створити обліковий запис",
  "login.js878212959" : "Надіслати новий пароль",
  "editMediaDialog.js808123558" : "Медіа",
  "markdowneditfield.js554353551" : "Шрифти",
  "markdowneditfield.js558691581" : "Розділ 1",
  "markdowneditfield.js785963602" : "Розділ 2",
  "markdowneditfield.js935699377" : "Розділ 3",
  "markdowneditfield.js534085527" : "Розділ 4",
  "markdowneditfield.js518723970" : "Розділ 5",
  "markdowneditfield.js452157683" : "Розділ 6",
  "markdowneditfield.js267080676" : "Курсив (виділений текст)",
  "markdowneditfield.js267174421" : "Жирний курсив (виділений текст)",
  "markdownhelper.js160663836" : "Медіа-об'єкт не знайдено",
  "markdownhelper.js744897963" : "Фільтр",
  "markdownhelper.js297030165" : "Показати місце",
  "markdownhelper.js702490738" : "Відкрийте зовнішній веб-сайт",
  "quizErrorDialog.js818208140" : "Для вікторини обов'язкова назва.",
  "quizErrorDialog.js316989684" : "Будь ласка, введіть принаймні одне запитання.",
  "quizErrorDialog.js217047946" : "Запитання ",
  "quizErrorDialog.js592184179" : "Запитання ",
  "quizErrorDialog.js91808612" : "Запитання ",
  "quizErrorDialog.js770785722" : "Запитання ",
  "quizErrorDialog.js121149031" : "Запитання ",
  "lazygooglemap.js821091857" : "На жаль, з міркувань захисту даних, вміст Google Maps не може відображатися в цьому субдомені.",
  "media.js334165907" : "Помилка в описі карти",
  "media.js745432641" : "Помилка в описі карти",
  "QuizEditorTitlePage.js567873608" : "Головна сторінка",
  "QuizEditorTitlePage.js3575109" : "Опис",
  "QuizQuestion.js522602069" : "Сформулюйте своє запитання тут",
  "QuizQuestion.js277305215" : "Додайте відповіді та поставте галочку біля правильних відповідей",
  "QuizQuestion.js719954254" : "Пояснення до відповіді можна додати тут",
  "QuizQuestion.js631196400" : "Ось скільки балів має бути за правильну відповідь",
  "streetview.js903524730" : "На жаль, з міркувань захисту даних, вміст Google Maps не може відображатися в цьому субдомені.",
  "youtube.js541386012" : "На жаль, контент YouTube не може відображатися на цьому субдомені з міркувань захисту даних.",
  "circularmenu.js381995722" : "Пошук",
  "circularmenu.js524039849" : "Вимірювання часових інтервалів на часовій шкалі",
  "circularmenu.js739065959" : "Користувацькі налаштування",
  "circularmenu.js357509198" : "Додати в закладки",
  "circularmenu.js636940232" : "Всі групи на",
  "circularmenu.js355370525" : "Створіть нову hiStory",
  "circularmenu.js753068960" : "Створіть часову подію",
  "circularmenu.js653781889" : "Створіть подію часового відрізка",
  "menu.js826958696" : "Політика конфіденційності",
  "menu.js586813367" : "ОУП та умови використання",
  "menu.js614038001" : "Відбиток",
  "menu.js439856996" : "Ліцензії на зображення",
  "menu.js942699906" : "Скинути налаштування файлів cookie",
  "printdialog.js317202399" : "Резолюція",
  "printdialog.js176250960" : "Часовий діапазон",
  "printdialog.js161420731" : "Налаштування зображення",
  "printdialog.js399246718" : "Поділитися зображенням",
  "printdialog.js150161449" : "Попередній перегляд",
  "printdialog.js8964786" : "Друк",
  "printdialog.js544885797" : "Експорт зображень",
  "ratings.js661212453" : "Щоб мати змогу оцінювати, ви повинні увійти в систему.",
  "displaystorydetails.js121722705" : "Показати ліцензії на зображення",
  "bookingrightsadministration.js131215424" : "Ці користувачі мають право редагувати hiStory:",
  "dummyuserhint.js706662830" : "Без реєстрації ви можете прочитати hiStory лише на сайті",
  "newstoryhint.js223326167" : () => (<>Тут ви розповідаєте свою hiStory, яка формує каркас вашої шкали часу.<br/>Наразі, однак, достатньо дати вашій hiStory назву і зберегти її.<br/>Після цього ви можете почати вводити окремі події.<br/></>),
  "bookingrights.js286057285" : "hiStory може бути опублікована",
  "distributerightsdialog.js87761818" : "Роздавати права,",
  "barexpansion.js878339034" : "Висота",
  "barexpansion.js887101724" : "-скласти",
  "barexpansion.js728758094" : "Встановіть висоту події, що відображається, кратною нормальній висоті.",
  "bargroup.js152857522" : "Група",
  "bargroup.js914347576" : "Змінити позначення",
  "barposition.js84416775" : "Посада",
  "barposition.js560624624" : "Використовуйте позицію, щоб визначити, які події повинні відображатися внизу, а які вгорі.",
  "barsettings.js434654385" : "Колір",
  "barsettings.js408327828" : "Виберіть колір для події. Колір шрифту вибирається автоматично.",
  "barshape.js315964835" : "Форма",
  "barshape.js230080019" : "Виберіть форму панелі подій.",
  "dataset.js205796920" : "Дані діаграми",
  "dataset.js666145317" : "Опишіть залежні від часу діаграми.",
  "dataset.js354018274" : "Вставте CSV-вміст сюди і натисніть OK:",
  "dataset.js218289977" : "Імпорт",
  "editbookingdetails.js985514551" : "Ви дійсно хочете видалити подію?",
  "imagechooser.js862610110" : "Зображення до події на часовій шкалі",
  "imagechooser.js871519548" : "Позначте зображення, яке має з'явитися на монтажному столі.",
  "imagechooser.js950070103" : "Ви можете додати більше фотографій в опис.",
  "wikipediareference.js272862577" : "Посилання на Вікіпедію",
  "wikipediareference.js904830442" : "Посилання на Вікіпедію.",
  "bookmarks.js523603774" : "Ви дійсно хочете видалити закладку '{deleteBookmarkS && deleteBookmarkS.name}'?",
  "chooseMapDialog.js720327007" : "Назви ім'я.",
  "chooseMapDialog.js760709110" : "Потім клацніть на карті, щоб встановити новий маркер, або введіть URL-адресу з Google Maps.",
  "chooseMapDialog.js388797867" : "Введіть опис зображення та ліцензію, якщо ваше зображення не є вільним від роялті.",
  "chooseMapDialog.js842057153" : "Відрегулюйте розмір на карті. Додайте контрольні точки (щонайменше три) за допомогою [Ctrl + Click].",
  "chooseMapDialog.js982829124" : "Потім виберіть ",
  "Link.js776621301" : "Подія на часовій шкалі",
  "Link.js501324063" : "Відкрийте ще одну історію.",
  "import.js93314536" : "Імпорт з MS-Excel",
  "import.js254441848" : "Імпорт з текстового поля",
  "login.js15485281" : "Будь ласка, введіть адресу електронної пошти, яку ви зареєстрували на сайті histomania:",
  "markdowneditfield.js426937653" : "Жирний шрифт (виділений текст)",
  "markdowneditfield.js85888028" : "Вставити зображення",
  "markdowneditfield.js249402737" : "Вставити відео",
  "markdowneditfield.js421012641" : "Вставити карту",
  "markdowneditfield.js535534111" : "Вставити панорамний вид",
  "markdowneditfield.js58232052" : "Вставити фільтр",
  "markdowneditfield.js954635745" : "Вставити тест",
  "markdowneditfield.js827493202" : "Перелік усіх засобів масової інформації",
  "markdownhelper.js904359583" : "Фільтруйте події за хронологією та часовою шкалою.",
  "markdownhelper.js133555219" : "Знайти подію на шкалі часу",
  "markdownhelper.js499018470" : "Стрілка показує положення події на шкалі часу.",
  "markdownhelper.js743956038" : "Місце події відображається на карті.",
  "markdownhelper.js813604085" : "Відкриється нова вкладка браузера зі статтею.",
  "addstorydescriptor.js556883219" : "Вбудовані hiStories",
  "addstorydescriptor.js110462036" : "новий вбудований hiStory",
  "bookingrights.js50879320" : "Підпишіться, щоб поділитися історіями з іншими.",
  "admincomponent.js149123880" : "Закрити",
  "loginbutton.js370395105" : "реєстр",
  "mediaitemlistbutton.js846631503" : "Перелік усіх засобів масової інформації",
  "registerbutton.js180629076" : "Зареєструватися безкоштовно",
  "hintdialog.js158836303" : "Близько,",
  "barexpansion.js805637512" : "нормальний",
  "barexpansion.js271938727" : "25-кратний",
  "barexpansion.js680468558" : "50-кратний",
  "barexpansion.js321153821" : "75-кратний",
  "barexpansion.js970143224" : "100-кратний",
  "barposition.js477225547" : "внизу",
  "barposition.js21166688" : "нормальний",
  "barposition.js400652493" : "вгорі",
  "bookingrightsadministration.js501993017" : "Авторизуйте інших користувачів,",
  "streetview.js459438593" : "Я погоджуюсь, що мені може бути показаний контент з Карт Google. Це може призвести до передачі персональних даних третім особам.",
  "displaystorydetails.js463453345" : "Події. Натисніть на запис, щоб дізнатися подробиці, або на символ годинника, щоб знайти його на часовій шкалі.",
  "dataset.newLine" : "новий рядок",
  "dataset.fromCSV" : "з csv",
  "markdowneditfield.name" : "Ім'я",
  "markdowneditfield.birth" : "Дата народження",
  "addmarkdownbutton.53645" : "Це заголовок уцінки",
  "timeblock.3457335" : "Вказуйте або тривалість, або кінець, інше значення розраховується в кожному випадку. Якщо не вказано, то кінець відраховується до поточного часу.",
  "innerevents.34353463" : "Наразі для введення доступний лише редактор JSON.",
  "bargroup.54345345" : "Події однієї групи відображаються у позначеному блоці на часовій шкалі.<br",
  "cookieconsent.0": "Перш ніж продовжити",
  "cookieconsent.1" : "Технічно необхідні файли cookie",
  "cookieconsent.2" : () => <>Оперативні файли cookie не можна деактивувати, інакше наш сайт більше не буде функціонувати.<br/>Наприклад, ми використовуємо сесійні файли cookie, щоб зберігати поточний вибір ваших hiStories.<br/>Крім того, сторінки з YouTube і Google Maps можуть бути вбудовані у створений користувачем контент.<br/>В результаті вищезгадані виробники також можуть встановлювати файли cookie. <br/>Перед завантаженням цього контенту ми запитаємо вас, чи хочете ви це зробити.<br/>Ми запам'ятовуємо ваш вибір у локальному сховищі браузера.<br/><br/></>,
  "cookieconsent.3" : "Аналітичні файли cookie",
  "cookieconsent.4" : "Ми використовуємо Google Analytics для створення статистики. При цьому не обробляються персональні дані. IP-адреси зберігаються анонімно (останні цифри замінюються на X).",
  "cookieconsent.5" : "Ви можете будь-коли скасувати налаштування файлів cookie та локального сховища, вибравши в меню пункт \"Скинути налаштування файлів cookie\".",
  "schoolcookieconsent.js645797755": "ГАРАЗД.",
  "enlargeToSelection" : "Збільшити при виборі",
  "fixDuration" : "Фіксована тривалість",
  "endMeasurement" : "Кінцевий вимір",
  "secondsFromNow" : "За кілька секунд",
  "minutesFromNow" : "За кілька хвилин",
  "hoursFromNow" : "Через кілька годин",
  "daysFromNow" : "Через кілька днів",
  "chronology" : "хронологія",
  "languageinput.js593099979" : "мова",
  "fontSettings.js878339034" : "Шрифт",
  "fontSettings.js887101724" : "-скласти",
  "fontSettings.js728758094" : "Встановіть висоту підпису як кратну звичайній висоті.",
  "fontSettings.quarter" : "1",
  "fontSettings.half" : "1/2",
  "fontSettings.threequarters" : "3/4",
  "fontSettings.full" : "нормальний",
  "fontSettings.oneandahalf" : "1,5 рази",
  "fontSettings.two" : "подвійний",

  "fontSettings.transparency": "непрозорий",
  "fontSettings.height": "Висота",
  "fontSettings.25percent": "25%",
  "fontSettings.50percent": "50%",
  "fontSettings.75percent": "75%",
  "fontSettings.100percent": "100%",
  "fontSettings.bold": "сміливий",
  "fontSettings.italic": "курсив",

  "deleteProfile": "Видалити мій профіль",
  "deleteProfileContent": () => <>Видалення вашого профілю не видаляє ваші hiStories.<br/>Вони можуть залишатися видимими для інших користувачів під анонімним профілем.<br/>Якщо ви хочете, щоб ваші hiStories більше не були доступними для інших, видаліть їх перед тим, як видалити свій профіль.<br/><br/>Хочете видалити свій профіль безповоротно?</>,

  "decorationdescriptor.js556883219": "оздоблення",
  "decorationdescriptor.js92624992": "оздоблення",
  "menu.mapForTimeInterval": "Зведена карта з часового інтервалу",
  "relation.js272862577" : "з'єднувальні лінії",
  "relation.js904830442" : "з'єднувальні лінії",

  "connection.target" : "Ціль посилання",
  "connection.name" : "Призначення",
  "connection.color" : "Колір",
  "connection.width" : "Ширина",
  "connection.textpos" : "Положення тексту",
  "connection.startpos" : "Початкова позиція",
  "connection.endpos" : "Кінцеве положення",

  "schoolclass6" : "6",
  "schoolclass7" : "7",
  "schoolclass8" : "8",
  "schoolclass9" : "9",
  "schoolclass10" : "10",
  "schoolclass11" : "11",
  "schoolclass12" : "12",
  "schoolclass13" : "13",

  "curriculum.title" : "привіт Історії за класами школи",
  "curriculum.text" : "Тут ви знайдете вибрані hiStories, згруповані за шкільними класами. Оскільки кожна федеральна земля має власну навчальну програму, розподіл за категоріями можливий лише приблизно.",

  "welcome.about" : "про histomania"
}

export default res;
