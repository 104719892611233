import {Resource} from 'react-canvas-timeline';
import i18n from "../i18n/i18n";

/**
 * Eine Timeline
 */
class Story extends Resource {
    constructor(id, name=i18n("story.js915248213"), secname, deleted, desc, isAdmin, changeuser, userimg, changetime, imgSrc, wikiimg, wikiimglicense, mapDescriptor, imagelicense, sourcereference, wikipage, isPrivateB = 1, storyurlP) {
        super(id, name, secname, deleted);
        this.isAdmin = isAdmin;
        this.changeuser = changeuser;
        this.changetime = changetime;
        this.members = [];
        this.isPrivate = isPrivateB;
        this.imgSrc = imgSrc;
        this.changeUserImg = userimg;
        this.description = desc;
        this.wikiimg = wikiimg;
        this.wikiimglicense = wikiimglicense;
        this.mapDescriptor = mapDescriptor;
        this.imagelicense = imagelicense;
        this.sourcereference = sourcereference;
        this.wikipage = wikipage;
        this.storyurl = storyurlP;
    }

    clone() {
        let story = new Story(this.id, this.name, this.secname, this.deleted, this.description, this.isAdmin, this.changeuser, this.changeUserImg, this.changetime, this.imgSrc, this.wikiimg, this.wikiimglicense, this.mapDescriptor, this.imagelicense, this.sourcereference, this.wikipage, this.isPrivateB, this.storyurl);
        story.refbylinktypes = this.refbylinktypes;
        story.wdreferences = this.wdreferences;
        story.isCategory = this.isCategory;
        story.wikipage = this.wikipage;
        story.longitude = this.longitude;
        story.latitude = this.latitude;

        //Falls es Zeiten gibt, dann werden die auch an die Story geschrieben
        story.startYear = this.startYear;
        story.startMonth = this.startMonth;
        story.startDate = this.startDate;
        story.startHour = this.startHour;
        story.startMinute = this.startMinute;

        story.endYear = this.endYear;
        story.endMonth = this.endMonth;
        story.endDate = this.endDate;
        story.endHour = this.endHour;
        story.endMinute = this.endMinute;

        story.collapsedgroups = this.collapsedgroups;
        story.language = this.language;
        story.addstorydescriptor = this.addstorydescriptor;
        story.decorationdescriptor = this.decorationdescriptor;

        story.isFullyLoaded = this.isFullyLoaded;

        return story;
    }

    getMarkingColor() {
        return this.isAdmin ? "#EE0000" : null;
    }

    setMembers(members) {
        this.members = members;
    }

    getMembers() {
        return this.members;
    }

    getChangeUserName() {
        return this.changeuser;
    }

    getChangeTime() {
        return this.changetime;
    }

    setImgSrc(s) {
        this.imgSrc = s
    }

    getImgSrc() {
        return this.imgSrc;
    }

    getChangeUserImg() {
        return this.changeUserImg;
    }

    hasAdminRights() {
        return this.isAdmin;
    }

    setDescription(desc) {
        this.description = desc;
    }

    getDescription() {
        return this.description;
    }

    isPrivateB() {
        return this.isPrivate;
    }

    setPrivateB(p) {
        this.isPrivate = p;
    }

    setMapDescriptor(mapDescriptor) {
        this.mapDescriptor = mapDescriptor;
    }

    getMapDescriptor() {
        return this.mapDescriptor;
    }

    setImageLicense(d) {
        this.imagelicense = d;
    }

    getImageLicense() {
        return this.imagelicense;
    }

    setSourceReference(d) {
        this.sourcereference = d;
    }

    getSourceReference() {
        return this.sourcereference;
    }

    setWikiPage(d) {
        this.wikipage = d;
    }

    getWikiPage() {
        return this.wikipage;
    }

    getStoryURL() {
        return this.storyurl;
    }

    setStoryURL(url) {
        this.storyurl = url;
    }
}

export default Story;
