import Filter from "../images/icons/filter";
import PostAddIcon from "@material-ui/icons/PostAdd";
import React from "react";
import RoomIcon from "@material-ui/icons/Room";
import ScheduleIcon from '@material-ui/icons/Schedule';
import i18n from "../i18n/i18n";

const Explanation = () => (
    <div style={{fontSize: '1.2rem'}}>
      {i18n("explanation")()}
    </div>
)

export default Explanation;
