import React from 'react';
import 'whatwg-fetch';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Config from './config/config.js';
import {Helper} from 'react-canvas-timeline';
import styles from './ui-components/lib/styles';
import ExpandableCardHeader from './ui-components/lib/expandablecardheader';
import TransferHandler from './transfer/transferhandler.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Details from './ui-components/details';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Google } from "./images/icons/Google__G__Logo.svg";
import { ReactComponent as Twitter } from "./images/icons/X_logo_2023.svg";
import SchoolIcon from '@material-ui/icons/School';
import Facebook from '@material-ui/icons/Facebook';
import i18n from "./i18n/i18n";
import OKButton from "./ui-components/buttons/text/okbutton";
import MyButton from "./ui-components/buttons/text/mybutton";
import SvgIcon from "@material-ui/core/SvgIcon";


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCard: this.props.initialCard || "login",
            loginMail: "",
            loginPass: "",
            rememberMe: false,
            dialogOpen: null,
            newUserForename: "",
            newUserSurname: "",
            newUserEmail: "",
            newUserCompany: "",
            acceptTermsOfUse: false,
            errorFields: []
        };
        this.login = this.login.bind(this);
        this.showRequestNewPassword = this.showRequestNewPassword.bind(this);
        this.requestNewPassword = this.requestNewPassword.bind(this);
        this.toggleRememberMe = this.toggleRememberMe.bind(this);
        this.leagueLoginSuccessFunc = this.leagueLoginSuccessFunc.bind(this);
        this.leagueLoginErrorFunc = this.leagueLoginErrorFunc.bind(this);
        this.loginWithVidis = this.loginWithVidis.bind(this);
        this.loginWithEdulog = this.loginWithEdulog.bind(this);
        this.loginWithFobizz = this.loginWithFobizz.bind(this);
        this.loginWithFacebook = this.loginWithFacebook.bind(this);
        this.loginWithGoogle = this.loginWithGoogle.bind(this);
        this.loginWithTwitter = this.loginWithTwitter.bind(this);
    }

    componentDidMount() {

    }

    leagueLoginSuccessFunc(json) {
            localStorage.removeItem('histomania_rememberme_key');
            localStorage.removeItem('histomania_rememberme_user');
            //URL aufrufen
            window.location = json.url;
    }

    leagueLoginErrorFunc(code, json) {
            if (code === 500) {
                //Interner Serverfehler
                console.log(json);
                this.setState({dialogOpen: "INTERNAL_ERROR"});
            } else if (code === 902) {
                //User oder Passwort falsch
                this.setState({dialogOpen: "WRONG_USER_OR_PWD"});
            } else if (code === 404) {
                //User oder Passwort falsch
                this.setState({dialogOpen: "NOT_FOUND"});
            } else {
                this.setState({dialogOpen: "NETWORK_ERROR"});
            }
    }

    loginWithFacebook() {
        //Die URL für die Anmeldung holen und aufrufen
        TransferHandler.request(Config.getLoginWithFacebookURL(), this.leagueLoginSuccessFunc, this.leagueLoginErrorFunc, null);
    }

    loginWithGoogle() {
        //Die URL für die Anmeldung holen und aufrufen
        TransferHandler.request(Config.getLoginWithGoogleURL(), this.leagueLoginSuccessFunc, this.leagueLoginErrorFunc, null);
    }

    loginWithTwitter() {
        //Die URL für die Anmeldung holen und aufrufen
        TransferHandler.request(Config.getLoginWithTwitterURL(), this.leagueLoginSuccessFunc, this.leagueLoginErrorFunc, null);
    }

    loginWithVidis() {
        //https://repo.vidis.schule/repository/vidis-cdn/data/idps.json

        const script = document.createElement('script');
        script.src = 'https://repo.vidis.schule/repository/vidis-cdn/latest/vidisLogin.umd.js';
        script.async = true;
        document.body.innerHTML = "";
        document.body.appendChild(script);

        script.onload = () => {
            // Nachdem das Skript geladen wurde, den Button auslösen
            const vidisLogin = document.createElement('vidis-login');
            vidisLogin.setAttribute('loginurl', Config.getLoginWithVIDISURL());
            vidisLogin.setAttribute('size', 'S');
            vidisLogin.setAttribute('cookie', 'false');
            //vidisLogin.style.display = 'none'; // Button unsichtbar machen

            document.body.appendChild(vidisLogin);

            const shadowRoot = vidisLogin.shadowRoot;
            if(shadowRoot) {
                const underlyingButtons = shadowRoot.querySelectorAll('button');
                if (underlyingButtons && underlyingButtons.length == 1) { //Ist nur ein Button da, dann wird dieser automatisch geklickt
                    underlyingButtons[0].click(); // Den Button auslösen
                }
            }
        };
    }

    loginWithFobizz() {
        //Die URL für die Anmeldung holen und aufrufen
        TransferHandler.request(Config.getLoginWithFobizzURL(), this.leagueLoginSuccessFunc, this.leagueLoginErrorFunc, null);
    }

    loginWithEdulog() {
        //Die URL für die Anmeldung holen und aufrufen
        TransferHandler.request(Config.getLoginWithEdulogURL(), this.leagueLoginSuccessFunc, this.leagueLoginErrorFunc, null);
    }



    login() {
        let encryptedPass = Helper.sha1(this.state.loginPass + this.state.loginMail.toLowerCase());

        let transferObj = {
            email: this.state.loginMail,
            pass: encryptedPass,
            rememberme: this.state.rememberMe,
            width: window.innerWidth,
            height: window.innerHeight
        }

        let successFunc = (json) => {
            //Falls "Remember me" angefordert wurde, den entsprechenden Eintrag im Storage machen, ansonsten löschen
            if (this.state.rememberMe) {
                localStorage.setItem('histomania_rememberme_key', json.autologinkey);
                localStorage.setItem('histomania_rememberme_user', this.state.loginMail);
            } else {
                localStorage.removeItem('histomania_rememberme_key');
                localStorage.removeItem('histomania_rememberme_user');
            }
            this.props.onLogIn(json.sessionid, json.email, json.forename, json.surname, json.company, json.isdummy, json.hasnewsletter, json.setting_detailwindowtype, json.setting_maxlabellength, json.setting_orientationtype);
        }

        let SELF = this;
        let errorFunc = (code, json) => {
            if (code === 500) {
                //Interner Serverfehler
                SELF.setState({dialogOpen: "INTERNAL_ERROR"});
            } else if (code === 902) {
                //User oder Passwort falsch
                SELF.setState({dialogOpen: "WRONG_USER_OR_PWD"});
            } else if (code === 404) {
                //User oder Passwort falsch
                SELF.setState({dialogOpen: "NOT_FOUND"});
            } else {
                SELF.setState({dialogOpen: "NETWORK_ERROR"});
            }
        }

        TransferHandler.request(Config.getLoginURL(), successFunc, errorFunc, transferObj);
    }

    showRequestNewPassword() {
        this.setState({dialogOpen: null, openCard: "lostPassword"});
    }

    requestNewPassword() {
        let transferObj = {
            email: this.state.loginMail
        }

        let successFunc = (json) => {
            //Bei Erfolg das Callback aufrufen
            this.setState({dialogOpen: "NEW_PASSWORD_SENT"});
        }
        let errorFunc = (code, json) => {
            if (code === 1) {
                //Interner Serverfehler
                this.setState({dialogOpen: "INTERNAL_ERROR"});
            } else if (code === 789) {
                this.setState({dialogOpen: "USER_NOT_FOUND"});
            } else {
                this.setState({dialogOpen: "NETWORK_ERROR"});
            }
        }

        TransferHandler.request(Config.getRequestNewPasswordURL(), successFunc, errorFunc, transferObj);
    }

    createAccount() {
        let transferObj = {
            email: this.state.newUserEmail,
            forename: this.state.newUserForename,
            surname: this.state.newUserSurname,
            company: this.state.newUserCompany,
            newsletter: this.state.newsletter
        }

        let successFunc = (json) => {
            //Bei Erfolg das Callback aufrufen
            this.setState({dialogOpen: "ACCOUNT_CREATED"});
        }
        let errorFunc = (code, json) => {
            if (code === 998) {
                //Plausicheck failed
                let dlg = null;
                if(json.fields.indexOf("forename") >= 0) {
                    dlg = "PRENAME_ERROR";
                } else if(json.fields.indexOf("surname") >= 0) {
                    dlg = "SURNAME_ERROR";
                } else if(json.fields.indexOf("email") >= 0) {
                    dlg = "EMAIL_ERROR";
                }

                this.setState({errorFields: json.fields, dialogOpen: dlg});
            } else if (code === 920) {
                //Doppelter Eintrag
                this.setState({dialogOpen: "DUPLICATE_ENTRY"});
            } else if (code === 1) {
                //Interner Serverfehler
                this.setState({dialogOpen: "INTERNAL_ERROR"});
            } else {
                this.setState({dialogOpen: "NETWORK_ERROR"});
            }
        }

        TransferHandler.request(Config.getCreateAccountURL(), successFunc, errorFunc, transferObj);
    }

    closeDialog() {
        //Falls status = ACCOUNT_CREATED, dann direkt in den Anmeldedialog abspringen und den User vorbelegen
        if (this.state.dialogOpen === "ACCOUNT_CREATED") {
            this.setState({dialogOpen: null, openCard: "login", loginMail: this.state.newUserEmail});
        } else {
            this.setState({dialogOpen: null});
        }
    }

    termsOfUsage() {
        window.open(Config.getAGBURL(), '_blank');
    }

    dataprotection() {
        //Neue Seite öffnen und Nutzungsbedingungen anzeigen
        window.open(Config.getDataprotectionURL(), '_blank');
    }

    toggleRememberMe() {
        this.setState({rememberMe: !this.state.rememberMe});
    }

    _renderContent() {
       const styles = {
            block: {
                maxWidth: 550
            },
            buttonDiv: {
                margin: 5,
                minWidth: 230
            },
            paper: {
                background: '#ffffe6',
                padding: 10,
                overflow: "hidden"
            },
            contentStyle: {
                overflowY: "auto",
                width: "100%"
            },
            fatLink: {
                textDecoration: 'underline',
                fontWeight: 'bold',
                cursor: 'pointer'
            }
        };

        let dialogTitle = null;
        let dialogContent = null;
        if (this.state.dialogOpen === "INTERNAL_ERROR") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("errorInternal")()}</Typography>);
        } else if (this.state.dialogOpen === "WRONG_USER_OR_PWD") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("errorMailOrPwdNotFound")(styles.fatLink, this.showRequestNewPassword)}</Typography>);
        } else if (this.state.dialogOpen === "NEW_PASSWORD_SENT") {
            dialogTitle = i18n("titleNewPasswordRequested");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("msgPasswordSent")()}</Typography>);
        } else if (this.state.dialogOpen === "NOT_FOUND") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("msgNotFound")()}</Typography>);
        } else if (this.state.dialogOpen === "NETWORK_ERROR") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("errorNetwork")()}</Typography>);
        } else if (this.state.dialogOpen === "ACCOUNT_CREATED") {
            dialogTitle = i18n("titleAccountCreated");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("msgAccountCreated")()}</Typography>);
        } else if (this.state.dialogOpen === "DUPLICATE_ENTRY") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("errorDuplicateUserEntry")()}</Typography>);
        } else if (this.state.dialogOpen === "PRENAME_ERROR") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("prenameError")}</Typography>);
        } else if (this.state.dialogOpen === "SURNAME_ERROR") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("surnameError")}</Typography>);
        } else if (this.state.dialogOpen === "MAIL_ERROR") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("mailError")}</Typography>);
        } else if (this.state.dialogOpen === "USER_NOT_FOUND") {
            dialogTitle = i18n("error");
            dialogContent = (
                <Typography variant="subtitle1" color="inherit">{i18n("errorUserNotFound")()}</Typography>);
        } else {
            dialogTitle = i18n("error");
            dialogContent = (<Typography variant="subtitle1" color="inherit">{i18n("errorUnknown")()}</Typography>);
        }


        const actions = [
            <OKButton
                key="close"
                color="primary"
                keyboardFocused={true}
                onClick={() => this.closeDialog()}
            />
        ];

        return (<div style={styles.contentStyle}>
            <div>
                <Card style={{marginBottom: 5}}>
                    <ExpandableCardHeader title={i18n("login.js758426829")} open={this.state.openCard === "login"}
                                          onOpen={() => this.setState({openCard: this.state.openCard === "login" ? "" : "login"})}/>

                    <Collapse in={this.state.openCard === "login"} transitionduration="500" unmountOnExit>
                        <CardContent>
                            <Paper style={styles.paper} elevation={5}>
                                <TextField
                                    label={i18n("login.js779088926")}
                                    value={this.state.loginMail}
                                    onChange={(evt) => this.setState({loginMail: evt.target.value})}
                                    fullWidth
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label={i18n("login.js163307733")}
                                    type="password"
                                    value={this.state.loginPass}
                                    onChange={(evt) => this.setState({loginPass: evt.target.value})}
                                    fullWidth
                                />
                                <br/>
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.rememberMe}
                                            onChange={(evt) => this.toggleRememberMe()}
                                        />
                                    }
                                    label={i18n("login.js293038552")}
                                />

                                <br/>

                                <MyButton onClick={this.login}
                                        style={styles.buttonDiv}>{i18n('login')}</MyButton>

                                {!window.isSchoolDomain && <>
                                    <hr style={{marginTop: 10, marginBottom: 10}}/>
                                    {i18n("or")}
                                    <br/>
                                    <br/>
                                    <MyButton variant="contained"
                                              color="primary"
                                              icon={<SvgIcon viewBox="0 0 24 24">
                                                  <Google/>
                                              </SvgIcon>}
                                              onClick={this.loginWithGoogle}
                                              style={styles.buttonDiv}>{i18n('loginWithGoogle')}</MyButton>
                                    <br/>
                                    <MyButton variant="contained"
                                              color="primary"
                                              icon={<SvgIcon viewBox="0 0 300 271">
                                                  <Twitter/>
                                              </SvgIcon>}
                                              onClick={this.loginWithTwitter}
                                              style={styles.buttonDiv}>{i18n('loginWithTwitter')}</MyButton>

                                </>
                                }
                                {window.isSchoolDomain && <>
                                    <hr style={{marginTop: 10, marginBottom: 10}}/>
                                    {i18n("or")}
                                    <br/>
                                    <br/>
                                    <MyButton variant="contained"
                                              color="primary"
                                              icon={<SchoolIcon/>}
                                              onClick={() => this.loginWithVidis()}
                                              style={styles.buttonDiv}>{i18n('loginWithVIDIS')}</MyButton>
                                    <br/>
                                    <MyButton variant="contained"
                                              color="primary"
                                              icon={<SchoolIcon/>}
                                              onClick={()=>this.loginWithEdulog()}
                                              style={styles.buttonDiv}>{i18n('loginWithEdulog')}</MyButton>
                                    <br/>
                                    <MyButton variant="contained"
                                              color="primary"
                                              icon={<SchoolIcon/>}
                                              onClick={()=>this.loginWithFobizz()}
                                              style={styles.buttonDiv}>{i18n('loginWithFobizz')}</MyButton>
                                </>
                                }
                            </Paper>
                            <br/>
                        </CardContent>
                    </Collapse>
                </Card>

                <Card style={{marginBottom: 5}}>
                    <ExpandableCardHeader title={i18n("login.js116720706")}
                                          open={this.state.openCard === "noAccount"}
                                          onOpen={() => this.setState({openCard: this.state.openCard === "noAccount" ? "" : "noAccount"})}/>

                    <Collapse in={this.state.openCard === "noAccount"} transitionduration="500" unmountOnExit>
                        <CardContent>
                            <Paper style={styles.paper} elevation={5}>
                                <Typography>{i18n("createAccount")}</Typography>
                                <br/>
                                <TextField
                                    label={i18n("login.js319559879")}
                                    value={this.state.newUserForename}
                                    onChange={(evt) => this.setState({newUserForename: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("forename") >= 0 ? i18n("login.js278946069") : ""}
                                    fullWidth
                                />
                                <br/>
                                <TextField
                                    label={i18n("login.js897560837")}
                                    value={this.state.newUserSurname}
                                    onChange={(evt) => this.setState({newUserSurname: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("surname") >= 0 ? i18n("login.js237687098") : ""}
                                    fullWidth
                                />
                                <br/>
                                <TextField
                                    label={i18n("login.js457756656")}
                                    value={this.state.newUserEmail}
                                    onChange={(evt) => this.setState({newUserEmail: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("email") >= 0 ? i18n("login.js692708124") : ""}
                                    fullWidth
                                />
                                <br/>
                                {!window.isSchoolDomain && <>
                                    <br/>
                                    <Typography variant={"caption"}>{i18n("login.js382006560")}<br/>{i18n("newsLetterInfo")}</Typography>
                                    <br/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.newNewsletter}
                                                      onChange={() => this.setState({newsletter: !this.state.newsletter})}/>
                                        }
                                        label={i18n("subscribeNewsletter")}
                                    />
                                </>
                                }
                                <br/>
                                <MyButton onClick={() => this.termsOfUsage()}
                                        style={{margin: 10, color: "#4C68A7"}}>{i18n("login.js416865926")}</MyButton>
                                <MyButton onClick={() => this.dataprotection()}
                                        style={{margin: 10, color: "#4C68A7"}}>{i18n("login.js64439934")}</MyButton>
                                <br/>
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.state.acceptTermsOfUse}
                                            onChange={() => this.setState({acceptTermsOfUse: !this.state.acceptTermsOfUse})}
                                        />
                                    }
                                    label={<span
                                        style={this.state.acceptTermsOfUse ? {color: "black"} : {color: "red"}}>{i18n("login.js572661779")}</span>}
                                />
                                <br/>
                                <br/>
                                <MyButton disabled={!this.state.acceptTermsOfUse} onClick={() => this.createAccount()}>{i18n("login.js230957075")}</MyButton>
                            </Paper>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card style={{marginBottom: 5}}>
                    <ExpandableCardHeader title={i18n("login.js368777066")} open={this.state.openCard === "lostPassword"}
                                          onOpen={() => this.setState({openCard: this.state.openCard === "lostPassword" ? "" : "lostPassword"})}/>

                    <Collapse in={this.state.openCard === "lostPassword"} transitionduration="500" unmountOnExit>
                        <CardContent>
                            <Paper style={styles.paper} elevation={5}>
                                {i18n("login.js15485281")}
                                <br/>
                                <br/>
                                <TextField
                                    value={this.state.loginMail}
                                    onChange={(evt) => this.setState({loginMail: evt.target.value})}
                                    label={i18n("login.js327950210")}
                                    fullWidth
                                />
                                <br/>
                                <br/>
                                <MyButton onClick={() => this.requestNewPassword()}>{i18n("login.js878212959")}</MyButton>
                            </Paper>
                        </CardContent>
                    </Collapse>
                </Card>

            </div>

            <Dialog
                open={this.state.dialogOpen !== null}
                onClose={() => this.closeDialog()}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>

            </Dialog>
        </div>);
    }

    render() {
        const styles = {
            outerContainer: {
                height: "100%"
            }
        };

        return (
            <div style={styles.outerContainer}>
                <Details onClose={() => this.props.onClose()} title={i18n("login.js493155713")}>
                    {this._renderContent()}
                </Details>
            </div>
        )
    }
}

export default withStyles(styles)(Login);
