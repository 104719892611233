////////////////
// Title List //
////////////////

// Title List Container

import React from "react";
import Item from './item';
import MoreItem from './moreitem';
import {LCal, LCalFormatter, LCalHelper} from "react-canvas-timeline";
import LinearProgress from '@material-ui/core/LinearProgress';
import 'whatwg-fetch';
import './titlelist.scss';
import i18n from "../../i18n/i18n";
import BaseConfig from "../../config/config";
import Config from "../../config/config";

const getParameterByName = (name) => {
    let url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&"); //vorher: name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

class TitleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            moreData: []
        };
    }

    loadContent(dataLoaded, startIndex = 0, offset = 5) {
        this.setState({isLoading: true});

        const day = getParameterByName("day");
        const month = getParameterByName("month");
        const year = getParameterByName("year");

        let requestUrl = BaseConfig.getBaseURL()+'landingpage/' + this.props.url;

        let headers = new Headers({
            "Content-Type": "application/json"
        });

        let request = new Request(requestUrl, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({startIndex, offset, day, month, year})
        });

        fetch(request).then(function (response) {
            return response.json();
        }).then(function (json) {
            dataLoaded(json);
        }).catch(function (ex) {
            this.setState({isLoading: false});
            console.log("Es ist ein Fehler beim Aufruf von " + requestUrl + " aufgetreten:");
        }.bind(this));
    }

    componentDidMount() {
        this.loadContent((json) => this.setState({data: json, isLoading: false}));
    }

    componentWillUnmount() {
        this.setState({data: []});
    }


    render() {

        const getBackDrop = (item) => {
            let backDrop = Config.getBaseURL() +"images/default.png";
            if (item.wikiimg) {
                backDrop = Config.getBaseURL() +'image/getwikiimage.php?name=' + encodeURI(item.wikiimg);
            } else if (item.imgSrc) {
                backDrop = Config.getBaseURL() +'images/resourceimages/' + encodeURI(item.imgSrc);
            }
            return backDrop;
        }

        const getPreTitle = (item) => {
            const d = new LCal().initYMDHM(item.year, item.month, item.day);
            const now = new LCal().initNow();

            const day = getParameterByName("day");
            const month = getParameterByName("month");
            const year = getParameterByName("year");

            now.initYMDHM(year ? Number(year) : now.getYear(), month ? Number(month) : now.getMonth(), day ? Number(day) : now.getDay(), now.getHour(), now.getMinute(), now.getTimeZone(), now.getPrecision(), now.getType());


            const years = now.getDistanceInYMDHM(d)[0];
            const isBigDay = LCalHelper.isBigDay(years, this.props.isBirthday);
            const displYears = isBigDay ? <b style={{color: "red"}}>{years}</b> : <span>{years}</span>
            const preTitle = item.year && item.month && item.day ?
                <div>{LCalFormatter.formatDate(d) + " (vor "}{displYears}{" Jahren)"}</div> : null;
            return preTitle
        }

        let titles = null;
        if (this.state.data) {
            if (this.state.data.error) {
                //Gar nichts anzeigen, falls ein Fehler passiert, oder keine Daten zurückgeliefert werden
                return <div/>;
            } else {
                titles = this.state.data.map(function (item, i) {
                    if (i < 5) {
                        const backDrop = getBackDrop(item);
                        const preTitle = getPreTitle(item);

                        return (
                            <Item key={item.id}
                                  id={item.id}
                                  preTitle={preTitle}
                                  title={item.name}
                                  overview={item.description}
                                  backdrop={backDrop}
                                  imgLicense={item.imglicense}
                                  wikiImg={item.wikiimg}
                                  titleMini={this.props.titleMini}
                                  bgColor={"#F4F4F4"}
                                  onClick={() => this.props.onClick && this.props.onClick(item.id, item.hyperlink)}
                            />
                        );

                    } else {
                        return null;
                    }
                }.bind(this));
            }
        }

        let moreTitles = null;
        if (this.state.moreData) {
            if (this.state.moreData.error) {
                //die Liste moreTitles ist leer
            } else {
                moreTitles = this.state.moreData.map(function (item, i) {
                    const backDrop = getBackDrop(item);
                    const preTitle = getPreTitle(item);

                    return (
                        <MoreItem key={item.id} id={item.id}
                                  preTitle={preTitle}
                                  title={item.name}
                                  overview={item.description}
                                  backdrop={backDrop}
                                  imgLicense={item.imglicense}
                                  wikiImg={item.wikiimg}
                                  onClick={() => this.props.onClick && this.props.onClick(item.id, item.hyperlink)}
                        />
                    );
                }.bind(this));
            }
        }

        return (
                <div className="TitleListWrapper">
                    <div ref="titlecategory" className="TitleList">
                        <div className="Title">
                            <h2 id={this.props.id} style={{fontSize: 18}}>{this.props.title}</h2>
                            <div className="titles-wrapper">
                                {titles}
                            </div>
                            {this.state.moreData.length === 0 && !this.state.isLoading && this.state.data.length > 4 &&
                            <div className="more" onClick={() => this.loadContent((json) => this.setState({
                                moreData: json,
                                isLoading: false
                            }), 5, 100)}>{i18n("more")}</div>}
                            {this.state.moreData.length > 0 && !this.state.isLoading &&
                            <div className="more" onClick={() => this.setState({moreData: [], isLoading: false})}>{i18n("less")}</div>}
                            {this.state.isLoading && <LinearProgress/>}
                            <div className="moretitles-wrapper">
                                {moreTitles}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default TitleList;

