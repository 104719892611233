import React from 'react';
import Typography from '@material-ui/core/Typography';
import {LCalFormatter} from 'react-canvas-timeline';
import "./resourceitem.scss";

const SimpleResourceItem = ({res, model, pending, checked, onToggleResourceVisibility, onResShowNeighbourhood, onSearch, onResShowDetails, bgColor, checkboxIcon, pendingResourceIDs}) => {
  const onToggleResourceVisibilityF = (rid) => {
    onToggleResourceVisibility(rid);
  }

  let dStr = LCalFormatter.formatStartEnd(res, !!res.gender);
  let imgSrc = model.getIcon(res) ? model.getIcon(res).src : null

  return (<div className={'resourceItemWrapper'}>
    <div style={{background:  bgColor}}>
    <div className='resourceItemAccordionSummary' style={{cursor: 'pointer'}} onClick={(evt) => {onToggleResourceVisibilityF(res.id); evt.stopPropagation()}}>
      <div className='resourceItemDIV' >
        <div className='resourceItemImageWrapper' style={{width:  100, minWidth: 100, minHeight: 70, backgroundImage: 'url("'+imgSrc+'")', backgroundSize: "cover"}}>

        </div>
        <div className='resourceItemNameCol'>
          <div className='resourceItemNameRow'>
            <Typography variant="h6" style={{fontSize: "1rem"}}>
              {res.name ? res.name : dStr}
            </Typography>
            <Typography variant="caption"
                        className='resourceItemDist'>
              {res.distance ? " in " + res.distance + " km" : null}
            </Typography>
          </div>
          <Typography variant="caption" color="inherit">
            {(res.secname ? res.secname : "") + (res.name && dStr ? " (" + dStr
                + ")" : "")}
          </Typography>
          {res.lender && <><br/><Typography variant="caption" className='resourceItemLender'>
            von {res.lender}
          </Typography></>}
        </div>
      </div>
    </div>
  </div>
</div>)

}


export default SimpleResourceItem;
