import Story from "./data/story";
import BookingItem from "./data/bookingitem";
import {
    Helper,
    LCal,
    LCalFormatter,
    LCalInterval,
} from "react-canvas-timeline";
import generateUUID from "./markdown/generateUUID";

let nextNegativeEventID = -1;

export const getStartLCalFromJSON = (evt) => {
    //Im Fall, dass bei Monat oder Tag 0 steht wird entsprechend der Januar, bzw. der 1. genommen
    let month = evt.startMonth * 1 === 0 ? 1 : evt.startMonth * 1;
    let day = evt.startDay * 1 === 0 ? 1 : evt.startDay * 1;
    let startType = evt.startType || 580;
    let start = evt.startYear ? new LCal().initYMDHM(evt.startYear ? evt.startYear * 1 : evt.startYear, month, day, evt.startHour, evt.startMinute, "Europe/Berlin", evt.startPrecision, startType) : null;
    return start;
}

export const getEndLCalFromJSON = (evt) => {
    //Zunächst wie beim Start
    let month = evt.endMonth * 1 === 0 ? 1 : evt.endMonth * 1;
    let day = evt.endDay * 1 === 0 ? 1 : evt.endDay * 1;
    let endType = evt.endType || 582;
    let end = evt.endYear ? new LCal().initYMDHM(evt.endYear ? evt.endYear * 1 : evt.endYear, month, day, evt.endHour, evt.endMinute, "Europe/Berlin", evt.endPrecision, endType) : null;
    return end;
}

export const parseJSONListToTask = (jsontasks) => {
    let tasks = [];
    //Für jedes BookingItem ein Objekt erzeugen
    for (let jsonEvt of jsontasks) {
        tasks.push(parseJSONTask(jsonEvt));
    }
    return tasks;
}

export const parseJSONStory = (jsonRes) => {
    if (jsonRes.id) {
        let label = jsonRes.label;
        if (!label || label === "") {
            label = LCalFormatter.formatStartEnd(jsonRes, !!jsonRes.gender);
        }
        let story = new Story(jsonRes.id, label, jsonRes.secname, jsonRes.deleted, jsonRes.description, jsonRes.isAdmin, jsonRes.changeusername, jsonRes.userimg, jsonRes.changetime, jsonRes.imgSrc, jsonRes.wikiimg, jsonRes.wikiimglicense, getProcessedMapDescriptor(jsonRes.mapDescriptor), jsonRes.imagelicense, jsonRes.sourcereference, jsonRes.wikipage, jsonRes.isPrivate, jsonRes.storyurl);
        story.refbylinktypes = jsonRes.refbylinktypes;
        story.wdreferences = jsonRes.wdreferences;
        story.isCategory = jsonRes.isCategory;
        story.wikipage = jsonRes.wikipage;
        story.longitude = jsonRes.longitude;
        story.latitude = jsonRes.latitude;

        //Falls es Zeiten gibt, dann werden die auch an die Story geschrieben
        story.startYear = jsonRes.startYear;
        story.startMonth = jsonRes.startMonth;
        story.startDate = jsonRes.startDate;
        story.startHour = jsonRes.startHour;
        story.startMinute = jsonRes.startMinute;

        story.endYear = jsonRes.endYear;
        story.endMonth = jsonRes.endMonth;
        story.endDate = jsonRes.endDate;
        story.endHour = jsonRes.endHour;
        story.endMinute = jsonRes.endMinute;

        story.collapsedgroups = jsonRes.collapsedgroups;
        story.language = jsonRes.language;
        story.addstorydescriptor = jsonRes.addstorydescriptor;
        story.decorationdescriptor = jsonRes.decorationdescriptor;

        if (jsonRes.id > 0) {
            story.isFullyLoaded = true;
        }
        return story;
    } else {
        return null;
    }
}

export const parseJSONListToStories = (jsonresources) => {
    let resources = [];
    //Für jede Ressource ein Objekt erzeugen und zufügen
    for (let jsonRes of jsonresources) {
        let res = parseJSONStory(jsonRes);
        resources.push(res);
    }
    return resources;
}


export const parseJSONTask = (jsonEvt) => {
    let start = getStartLCalFromJSON(jsonEvt);
    let end;
    if (jsonEvt.startType === 585) { //Handelt es sich um einen Zeitpunkt?
        end = start;
    } else {
        end = getEndLCalFromJSON(jsonEvt);
    }

    let id = jsonEvt.id;
    //Bei negativen IDs handelt es sich um Events aus der Event-DB. Diese können öfter als ein Mal in Stories benutzt werden, werden aber auch nur ein Mal initial geladen.
    //Deswegen erhalten diese eine fortlaufende negative ID
    if (id < 0) {
        id = nextNegativeEventID;
        nextNegativeEventID--;
    }

    let innerEvents = [];
    let minInnerStart;
    let maxInnerEnd;
    if (jsonEvt.innerevents) {
        for (let evt of jsonEvt.innerevents) {
            let start = getStartLCalFromJSON(evt);
            let end;
            if (evt.startType === 585) { //Handelt es sich um einen Zeitpunkt?
                end = start;
            } else {
                end = getEndLCalFromJSON(evt);
            }

            if (start || end) {
                if (!minInnerStart || (start && start.getJulianMinutes() < minInnerStart.getJulianMinutes())) {
                    minInnerStart = start;
                }
                if (!maxInnerEnd || (end && end.getJulianMinutes() > maxInnerEnd.getJulianMinutes())) {
                    maxInnerEnd = end;
                }

                let interval = new LCalInterval(start, end);
                interval.color = evt.color;
                innerEvents.push(interval);
            }
        }
    }

    //Sind innerEvents vorhanden, aber kein Start- oder kein Ende, so werden diese aus den innerEvents bestimmt
    if (!start && minInnerStart) {
        start = minInnerStart;
    }
    if (end && maxInnerEnd && maxInnerEnd.after(end)) {
        end = maxInnerEnd;
    }

    let ri1 = new BookingItem(id, start, end, jsonEvt.resid, jsonEvt.label, jsonEvt.secname, jsonEvt.changeusername, jsonEvt.userimg, jsonEvt.changetime, jsonEvt.imgSrc, jsonEvt.wikiimg, jsonEvt.wikiimglicense, innerEvents, jsonEvt.description, getProcessedMapDescriptor(jsonEvt.mapDescriptor), jsonEvt.imagelicense, jsonEvt.sourcereference, jsonEvt.wikipage, jsonEvt.dataset);
    let bgCol = Helper.intColorToHexString(jsonEvt.color);
    ri1.getDisplayData().setColor(bgCol);
    ri1.getDisplayData().setShape(jsonEvt.shape);
    ri1.getDisplayData().setPosition(jsonEvt.position);
    ri1.getDisplayData().setExpansionFactor(jsonEvt.expansion);
    ri1.getDisplayData().setBarGroup(jsonEvt.bargroup);

    if(jsonEvt.fontSizeFactor) {
        ri1.getDisplayData().setFontSizeFactor(jsonEvt.fontSizeFactor);
    }
    if(jsonEvt.transparency) {
        ri1.getDisplayData().setTransparency(jsonEvt.transparency);
    }
    if(jsonEvt.bold) {
        ri1.getDisplayData().setBold(jsonEvt.bold);
    }
    if(jsonEvt.italic) {
        ri1.getDisplayData().setItalic(jsonEvt.italic);
    }

    ri1.wikipage = jsonEvt.wikipage;
    ri1.refbylinktypes = jsonEvt.refbylinktypes;
    ri1.wdreferences = jsonEvt.wdreferences;
    ri1.longitude = jsonEvt.longitude;
    ri1.latitude = jsonEvt.latitude;

    if(jsonEvt.connections) {
        try {
            ri1.connections = JSON.parse(jsonEvt.connections);
        } catch (e) {
            console.log(e);
        }
    }

    ri1.setDeleted(jsonEvt.deleted);

    if (id < 0) {
        ri1.proxyID = jsonEvt.id;
    } else {
        ri1.isFullyLoaded = true;
    }
    return ri1;
}

/**
 * Sanitize MapDescriptor
 */
const getProcessedMapDescriptor = (mapDescriptor) => {
    if(mapDescriptor) {
        if(mapDescriptor.trim().length === 0) {
            mapDescriptor = null;
        } else {
            let parsedMapDesc = JSON.parse(mapDescriptor);

            if(parsedMapDesc) {
                if(parsedMapDesc.objects && parsedMapDesc.center && parsedMapDesc.zoom) {
                    let newMapDesc = {
                        items: [{
                            "type":"GoogleDynamicMap",
                            ...parsedMapDesc
                        }]
                    }
                    parsedMapDesc = newMapDesc;
                }
                if(!parsedMapDesc.items || parsedMapDesc.items.length===0) {
                    mapDescriptor = null;
                } else {
                    parsedMapDesc.items && parsedMapDesc.items.filter(i => !i.uuid).forEach(i => i.uuid = generateUUID());
                    mapDescriptor = JSON.stringify(parsedMapDesc);
                }
            }
        }
    }
    return mapDescriptor;
}
