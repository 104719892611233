import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import types from "../types";
import ChooseEventLinkPanel from "./chooseEventLinkPanel";


const ChooseEventLinkDialog = model => {
    return function A({open, onOK, onCancel, item}) { //Die Funktion braucht einen Namen: https://github.com/facebook/react/issues/20531
        const cancelClicked = () => {
            onCancel();
        }
        return <Dialog open={open}
                       onClose={onCancel}
        >
            <DialogTitle>Ereignis-Link</DialogTitle>
            <DialogContent style={{overflow: "hidden"}}>
                <div style={{display: "flex", flexDirection: "column", height: "calc(100vH - 200px)", overflow: 'auto'}}>
                    <ChooseEventLinkPanel model={model} onOK={onOK}/>
                </div>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => cancelClicked()}/>
            </DialogActions>
        </Dialog>
    }
}

export default ChooseEventLinkDialog;
