import React, {useEffect, useState} from 'react';
import DataImage from "./dataimage";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import CancelIcon from "@material-ui/icons/Cancel";
import DrafteditorWrapper from "../drafteditor/drafteditorwrapper";
import ConverterUtils from "../drafteditor/utils/ConverterUtils";
import EditorUtils from "../drafteditor/utils/EditorUtils";
import MediaFullScreenWrapper from "../media/mediaFullScreenWrapper";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const ImageWithLicense = ({data, model, minWidth, imagelicense, onToggleResourceVisibility, onHighlight, onFilterClicked, onShowMap, pendingResourceIDs }) => {
  const [open, setOpen] = React.useState(false);
  const [imageLicenseEditorState, setImageLicenseEditorState] = useState(ConverterUtils.toEditorState(imagelicense, false));

  useEffect(() => {
    setImageLicenseEditorState(ConverterUtils.toEditorState(imagelicense, false));
  }, [imagelicense]);

  let displayImage = data.imgSrc && data.imgSrc !== "";
  if (!displayImage && data.id < 0) {
    let ico = model.getIcon(data);
    if (ico) {
      displayImage = true;
    }
  }

  return displayImage ? <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        key: imagelicense
      }}>
        <div style={{display: "flex", justifyContent: "center", margin: '0 40px 20px 40px', minWidth: minWidth}} >
          <Paper elevation={5} style={{border: "1px solid gray", overflow: "hidden" }}>
            <MediaFullScreenWrapper childs={(fullScreen)=><DataImage data={data} model={model} isMaxWidth={fullScreen}/>}/>
          </Paper>
        </div>
        {data.wikiimglicense &&
            <span>
                <br/>
                <Typography variant="caption">
                   <span dangerouslySetInnerHTML={{__html: "<span>" + data.wikiimglicense + "</span>"}}/>
                </Typography>
            </span>
        }
        {imageLicenseEditorState && EditorUtils.isEditorStateNotEmpty(imageLicenseEditorState) &&
            <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
              <div style={{zoom: "75%"}}>
                <DrafteditorWrapper
                    editorState={imageLicenseEditorState}
                    onChange={(val => setImageLicenseEditorState(val))}
                    readOnly={true}
                    model={model}
                    onEventLink={(id) => onHighlight(id)}
                    onStoryLink={(id) => onToggleResourceVisibility(id)}
                    onFilterLink={onFilterClicked}
                    onMapLink={onShowMap}
                    pendingResourceIDs={pendingResourceIDs}
                />
              </div>
            </div>
        }

        <Popover onClose={() => setOpen(false)}
                 open={open}
                 anchorEl={document.documentElement}
                 anchorOrigin={{
                   vertical: 'top',
                   horizontal: 'center',
                 }}
                 transformOrigin={{
                   vertical: 'center',
                   horizontal: 'center',
                 }}
        >
          <IconButton style={{position: 'absolute', top: 0, right: 0}}><div style={{borderRadius: 30, background: "gray", height: 35, width: 35}}><CancelIcon fontSize="large" style={{color: "white"}} onClick={()=>setOpen(false)}/></div></IconButton >
          <DataImage data={data}
                     model={model}
                     isMaxWidth
          />
        </Popover>
      </div>
      :
      <>
      </>
}

export default ImageWithLicense;
