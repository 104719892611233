import EditorUtils from "../../drafteditor/utils/EditorUtils";

const validate = values => {
  const errors = {}
  if (!values.title) {
    errors.noQuizTitle = 'X'
  }
  if (!values.questions || !values.questions.length) {
    errors.noQuestion = 'X'
  } else {
    const questionsArrayErrors = []
    values.questions.forEach((question, questionIndex) => {
      const questionErrors = {}
      if (!question || !question.question || question.question.length === 0) {
        const editorState = EditorUtils.toEditorState(question.question);
        if(!EditorUtils.isEditorStateNotEmpty(question.question)) {
          questionErrors.emptyQuestionString = 'X'
          questionsArrayErrors[questionIndex] = questionErrors;
        }
      }

      if (question && question.answers && question.answers.length) {
        const answerArrayErrors = [];
        question.answers.forEach((answer, answerIndex) => {
          if (!answer || !answer.length>0) {
            answerArrayErrors[answerIndex] = 'X'
          }
        })

        if (answerArrayErrors.length) {
          questionErrors.answers = answerArrayErrors;
          questionsArrayErrors[questionIndex] = questionErrors;
        }

        if (question.answers.length > 4) {
          questionErrors.tooMuchAnswers = 'X';
          questionsArrayErrors[questionIndex] = questionErrors;
        }
        if (!question.correctAnswer || question.correctAnswer.length === 0){
          questionErrors.correctAnswerRequired =  'X';
          questionsArrayErrors[questionIndex] = questionErrors;
         }
      } else if (!question.answers || question.answers.length === 0) {
        questionErrors.zeroAnswers = 'X';
        questionsArrayErrors[questionIndex] = questionErrors;
      }
    })

    if (questionsArrayErrors.length) {
      errors.questions = questionsArrayErrors
    }
  }
  return Object.keys(errors).length === 0 ? null : errors;
}

export default validate
