import React from "react";
import i18n from "../../i18n/i18n";
// Title List Item
class Item extends React.Component {
    render() {
        return (
            <div className="Item" style={{background: this.props.bgColor}} >
                <div onClick={() => this.props && this.props.onClick()}
                     style={{backgroundImage: "url('" + this.props.backdrop + "')"}}
                     className="InnerItem">
                    <div className="overlay">
                        <div className={"titleMini"}>{this.props.preTitle}</div>
                        <div className={this.props.titleMini ? "titleMini" : "title"}>{this.props.title}</div>

                        <div className="plot">{this.props.overview}</div>
                    </div>
                </div>
                {this.props.imgLicense && this.props.imgLicense !== '' && <div className="ImageLicense"
                     dangerouslySetInnerHTML={{__html: "<div><a href='https://commons.wikimedia.org/wiki/File:" + this.props.wikiImg + "'rel='nofollow noopener noreferrer' target='_blank'>"+i18n("image")+":</a> " + this.props.imgLicense + "</div>"}}/>}
            </div>

        );
    }
}

export default Item;
