import React from "react";
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import {renderers, transformLinkUri} from "../markdown/markdownhelper";
import ChooseImageDialog from "./inputcomponents/chooseImageDialog";
import ChooseVideoDialog from "./inputcomponents/chooseVideoDialog";
import ChooseMapDialog from "./inputcomponents/chooseMapDialog";
import ChoosePanoramaDialog from "./inputcomponents/chooseStreetViewDialog";
import ChooseQuizDialog from "./inputcomponents/chooseQuizDialog";
import ChooseInfoBlockDialog from "./inputcomponents/chooseInfoBlockDialog";
import ChooseLinkDialog from "./inputcomponents/chooseLinkDialog";
import ChooseEventLinkDialog from "./inputcomponents/chooseEventLinkDialog";
import ChooseStoryLinkDialog from "./inputcomponents/chooseStoryLinkDialog";
import ChooseFilterLinkDialog from "./inputcomponents/chooseFilterLinkDialog";
import ChooseMapLinkDialog from "./inputcomponents/chooseMapLinkDialog";
import ChooseMarkdownDialog from "./inputcomponents/chooseMarkdownDialog";
import Drafteditor from "./drafteditor";
import {Set} from "immutable";

//https://www.draft-js-plugins.com/

const DrafteditorWrapper = ({
  editorState,
  onChange,
  readOnly,
  style,
  model,
  onEventLink,
  onStoryLink,
  onMapLink,
  onFilterLink,
  pendingResourceIDs,
  legacyMapDescriptor,
  dense,
  showQuiz=true
}) => {
  return <Drafteditor
      style={style}
      editorState={editorState}
      onChange={onChange}
      readOnly={readOnly}
      model={model}
      onBrowserLink={(item)=>window.open(item.url, '_blank')}
      onEventLink={(item)=>onEventLink(item.eventid ? item.eventid : item)}
      onStoryLink={(item)=>onStoryLink(item.storyid ? item.storyid: item)}
      onFilterLink={onFilterLink}
      onMapLink={onMapLink}
      dense={dense}
      legacyMarkdownProps={{
        mapDescriptor: legacyMapDescriptor,
        mapDescriptorFallback: true,
        transformLinkUri: (link) => transformLinkUri(link),
        renderers: renderers((resid) => model.getResourceModel().getItemByID(resid), onStoryLink, onEventLink,  onMapLink, pendingResourceIDs, model, legacyMapDescriptor)
      }
      }
      ChooseImageComponent={ChooseImageDialog}
      ChooseVideoComponent={ChooseVideoDialog}
      ChooseMapComponent={ChooseMapDialog}
      ChoosePanoramaComponent={ChoosePanoramaDialog}
      ChooseQuizComponent={showQuiz ? ChooseQuizDialog : null}
      ChooseInfoBlockComponent={ChooseInfoBlockDialog}
      ChooseLinkComponent={ChooseLinkDialog}
      ChooseEventLinkComponent={ChooseEventLinkDialog(model)}
      ChooseStoryLinkComponent={ChooseStoryLinkDialog(model)}
      ChooseFilterLinkComponent={ChooseFilterLinkDialog(model)}
      ChooseMapLinkComponent={ChooseMapLinkDialog(model)}
      ChooseMarkdownComponent={ChooseMarkdownDialog}
  />
}

export default DrafteditorWrapper;

