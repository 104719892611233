import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import EditorUtils from "../utils/EditorUtils";
import DrafteditorWrapper from "../drafteditorwrapper";
import computedense from "../computedense";

const getNewItem = (content, marginLeft, marginRight, color, itemUuid) => {
    let item = {
        "content": content,
        "marginLeft": marginLeft * 1,
        "marginRight": marginRight * 1,
        "color": color,
        "uuid": itemUuid
    }

    return item;
}



const ChooseInfoBlockDialog = ({open, onOK, onCancel, item, model}) => {
    const [editorState, setEditorState] = useState(EditorUtils.toEditorState(item.content));
    const [marginLeft, setMarginLeft] = useState(item.marginLeft || 0);
    const [marginRight, setMarginRight] = useState(item.marginRight || 0);
    const [color, setColor] = useState(item.color || "fefff5");

    const itemUuid = item ? item.uuid : null;

    const cancelClicked = () => {
        setEditorState(EditorUtils.toEditorState(""));
        onCancel();
    }

    const okClicked = () => {
        onOK(getNewItem(EditorUtils.fromEditorState(editorState), marginLeft, marginRight, color, itemUuid));
        setEditorState(EditorUtils.toEditorState(""));
    }

    return <Dialog open={open}
                   onClose={onCancel}
                   fullWidth={true}
                   maxWidth={"md"}
    >
        <DialogTitle>Infoblock</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <DrafteditorWrapper
                    editorState={editorState}
                    onChange={(val) => setEditorState(val)}
                    readOnly={false}
                    model={model}
                    dense={computedense(window.innerWidth)}
                />
                <TextField
                    label="Hintergrundfarbe"
                    value={color}
                    onChange={(evt) => setColor(evt.target.value)}
                />
                <TextField
                    label="linker Abstand"
                    value={marginLeft}
                    onChange={(evt) => setMarginLeft(evt.target.value)}
                    type="number"
                />
                <TextField
                    label="rechter Abstand"
                    value={marginRight}
                    onChange={(evt) => setMarginRight(evt.target.value)}
                    type="number"
                />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={()=>cancelClicked()}/>
            <OKButton onClick={()=>okClicked()}/>
        </DialogActions>
    </Dialog>

}

export default ChooseInfoBlockDialog;
