import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Ukraine } from "../images/icons/flags/ukraine.svg";
import { ReactComponent as UK } from "../images/icons/flags/uk.svg";
import { ReactComponent as Germany } from "../images/icons/flags/germany.svg";

import React from "react";
import i18n from "../i18n/i18n";
import styles from '../ui-components/lib/styles';

const LanguageMenu = (languageMenuAnchorEl, languageMenuOpen, onClose,
    language,
    setLanguage) => (<Menu
    id="main-menu"
    anchorEl={languageMenuAnchorEl}
    open={languageMenuOpen}
    onClose={onClose}
>

  <List className={styles.listRoot}>
      <ListItem
          disabled={!language || language===""}
          button onClick={() => {
        setLanguage("");
        onClose();
      }}>
        <ListItemIcon>
          <SvgIcon viewBox="0 0 1000 500">
            <Germany/>
          </SvgIcon>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js869701443")}/>
      </ListItem>

      <ListItem
          disabled={language==="en"}
          button onClick={() => {
        setLanguage("en");
        onClose();
      }}>
        <ListItemIcon>
          <SvgIcon viewBox="0 0 1000 500">
            <UK/>
          </SvgIcon>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js503793771")}/>
      </ListItem>

      <ListItem
          disabled={language==="ua"}
          button onClick={() => {
        setLanguage("ua");
        onClose();
      }}>
        <ListItemIcon>
          <SvgIcon viewBox="0 0 1000 500">
            <Ukraine/>
          </SvgIcon>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js161223496")}/>
      </ListItem>
  </List>
</Menu>);


export default LanguageMenu;
