import React from "react";
import MyButton from "./mybutton";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import i18n from "../../../i18n/i18n";

const RegisterButton = ({onClick, style, autoFocus, disabled}) => (
    <MyButton icon={<PersonAddIcon/>} style={style} onClick={onClick} autoFocus={autoFocus} primary={true} disabled={disabled}>
      {i18n("registerbutton.js180629076")}
    </MyButton>
)

export default RegisterButton;
