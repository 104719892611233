import React from "react";
import Typography from "@material-ui/core/Typography";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import Filter from "../images/icons/filter";
import i18n from "./i18n";

const res = {
  "newStory": "Neue hiStory",
  "newStoryTooltipHeader": "Eine neue hiStory erstellen",
  "newStoryTooltipBody": "Erstellt den Rahmen für deine Erzählung, die Timeline und die Ereignisse.",
  "newEventTooltipHeader": "Ein neues Ereignis erstellen",
  "newEvent": "Neues Ereignis",
  "newEventTooltipBody1": "Erstellt ein neues Ereignis in der Timeline.",
  "newEventTooltipBody2": "Erstelle das Ereignis über das Kontextmenü ",
  "newEventTooltipBody3": "(Linksklick auf freie\nStelle)",
  "newEventTooltipBody4": " in der Timeline, falls du den Zeitpunkt direkt vorbelegen möchtest.",

  "search": "Suchen",
  "searchEventsAndStories": "Ereignisse und hiStories suchen",
  "searchEventsAndStoriesTooltip1": "- Suche nach Ereignissen in der Timeline während des Tippens",
  "searchEventsAndStoriesTooltip2": "- Suche nach hiStories",

  "filter": "Filtern",
  "filterEvents": "Ereignisse ausfiltern",
  "filterEventsTooltip1": "Ereignisse nach verschiedenen Kriterien ein- und ausblenden",

  "measure": "Zeiten messen",
  "measureTooltip": "Zeiten messen in der Timeline",

  "help": "Hilfe",

  "more": "mehr...",

  "loginInfoNotLoggedIn": "Du bist nicht angemeldet",
  "loginInfoDoLoginHere": "Hier anmelden.",
  "login": "Anmelden",
  "loginWithTwitter": "Anmelden mit X",
  "loginWithGoogle": "Anmelden mit Google",
  "loginWithFacebook": "Anmelden mit Facebook",
  "loginWithVIDIS": "Anmelden mit Schulaccount (VIDIS)",
  "loginWithFobizz": "Anmelden mit Fobizz",
  "loginWithEdulog": "Anmelden mit Schulaccount (edulog)",

  "storiesNearby": "hiStories in der Nähe",
  "searchStories": "hiStories durchsuchen",
  "myStories": "Meine hiStories",
  "recentlySeen": "Vor kurzem gesehen",
  "usedImagesAndSources": "Die verwendeten Bilder und ihre Quellen.",
  "sourcesOnlyGiven": "Quellen werden nur angegeben, falls der Autor diese hinterlegt hat.",
  "imageSourceReferences": "Bildquellen-Nachweise",

  "settings": "Einstellungen",
  "separatorTimelineDesc": "Trenner für Timeline/Artikeltext",
  "horizontal": "horizontal",
  "vertical": "vertikal",
  "showSidewaysDuringEventSelection": "bei Ereignisauswahl seitlich einblenden",
  "alwaysSuperimpose": "immer überlagernd einblenden",
  "automatic": "automatisch",
  "detailView": "Detailansicht",
  "eventLabeling": "Ereignisbeschriftung",
  "fullLabeling": "volle Beschriftung",
  "maxAsLongAsBar": "maximal so lang wie Ereignsibalken",
  "showTimelineBackgroundImage": "Hintergrundbild der Timeline",
  "showTimelineBackgroundImageTrue": "anzeigen",
  "showTimelineBackgroundImageFalse": "nicht anzeigen",
  "timelineTheme": "Timelinehintergrund",
  "dark": "dunkel",
  "bright": "hell",
  "chooseStory": "hiStory wählen",

  "close": "Schließen",
  "save": "Speichern",
  "ok": "OK",
  "cancel": "Abbrechen",
  "yes": "Ja",
  "no": "Nein",
  "delete": "Löschen",
  "copy": "Kopieren",
  "edit": "Bearbeiten",
  "back": "Zurück",
  "understoodAndSave": "Verstanden und speichern",

  "error": "Fehler",
  "errorInternal": () => (<>Es ist ein unbekannter interner Fehler aufgetreten.<br/>Bitte setze dich mit einem Ansprechpartner von histomania in Verbindung.</>),
  "errorMailOrPwdNotFound": (fatLink, showRequestNewPassword) => (<>Deine E-Mailadresse oder das eingegebene Passwort sind uns nicht bekannt.<br/>Falls du dein Passwort vergessen haben, so fordere bitte <span style={fatLink} onClick={showRequestNewPassword}>hier</span> ein neues an.</>),
  "msgPasswordSent": () => (<>Wir haben dir eine E-Mail mit einem Bestätigungslink gesendet.<br/>Bitte klicken diesen Link an, damit wir dir ein neues Passwort zusenden können.</>),
  "msgNotFound": () => (<>Das angeforderte Ereignis wurde nicht gefunden.<br/>Bitte setze dich mit einem Ansprechpartner von histomania in Verbindung.</>),
  "errorNetwork": () => (<>Es ist ein Fehler aufgetreten.<br/>Bitte stelle eine funktionierende Internetverbindung sicher.</>),
  "msgAccountCreated": () => (<>Dein Konto wurde erstellt.<br/>Wir haben dir deine Anmeldedaten per E-Mail gesendet.<br/>Bitte prüfe eventuell auch deinen Spam-Ordner.</>),
  "errorDuplicateUserEntry": () => (<>Wir konnten dein Konto nicht neu anlegen, da der Benutzer bereits in unserer Datenbank vorhanden war.<br/>Bitte melde dich mit deinen bestehenden Daten an, oder lass dir unter "Passwort vergessen" ein neues Passwort zusenden.</>),
  "errorUserNotFound": () => (<>Der Benutzer existiert nicht.</>),
  "errorUnknown": () => (<>Es ist ein unbekannter Fehler aufgetreten.<br/>Bitte setze dich mit einem Ansprechpartner von histomania in Verbindung.</>),
  "titleNewPasswordRequested": "Neues Passwort angefordert",
  "titleAccountCreated": "Kontoerstellung",
  "or": "oder",
  "createAccount": "Erstelle hier mit wenigen Angaben kostenlos dein neues histomania-Konto",
  "exportToExcel": "Daten nach MS-Excel exportieren",
  "addToWebsite": "Füge die aktuell angezeigten hiStories zu deiner Webseite hinzu, indem du den untenstehenden Code einfügst.",
  "codeMustBeInBodyTag": "Der Code muss innerhalb des body-Tags deines HTML-Dokuments stehen.",
  "embedAcceptAGB": "Indem du Inhalte von histomania in deine Website oder App einbettest, akzeptierst du die Allgemeinen Geschäfts- und Nutzungsbedingungen von histomania.",
  "permissions": "Berechtigungen",
  "profile": "Profil",
  "firstName": "Vorname",
  "lastName": "Nachname",
  "inputFirstName": "Bitte gib deinen Vornamen ein",
  "inputSurName": "Bitte gib deinen Nachnamen ein",
  "newsLetterInfo": "Der Newsletter erscheint etwa 5-6 mal pro Jahr und informiert dich über Neuerungen und ausgewählte hiStories.",
  "subscribeNewsletter": "Newsletter abonnieren",
  "loading": "lade",
  "changePassword": "Passwortänderung",
  "pwdChangeSuccessful": "Dein Passwort wurde erfolgreich geändert.",
  "pwdChangeErr": "Fehler beim Ändern des Passworts.",
  "pwdLength": "Das gewählte Passwort muss mindestens 8 Zeichen enthalten.",
  "pwdRepetitionError": "Das wiederholte Passwort unterscheidet sich vom ersten eingegebenen Passwort.",
  "pwdChange": "Passwort ändern",
  "pwdOld": "Altes Passwort",
  "pwdNew": "Neues Passwort (min. 8 Zeichen)",
  "pwdNewRepetition": "Neues Passwort (Wiederholung)",
  "showWikipediaTooltip": "Wikipedia-Artikel anzeigen",
  "whatIf": "Was wäre, wenn...",
  "changeTime": "Zeit ändern",
  "moveTimelineFromRefDate": "Verschiebe die Timeline von einem Referenzdatum auf ein anderes:",
  "moveTimelineByDuration": "Oder verschiebe um eine bestimmte Dauer:",
  "noteTimelineIsNotSaved": "Hinweis: die geänderte Timeline wird nicht gespeichert.",
  "moveMeasuringStick": "Verschiebe den Messschieber auf die angegebene Zeit",
  "moveMeasuringStickBy": "Messschieber verschieben um:",
  "moveMeasuringStickTo": "Messschieber verschieben auf:",
  "toPast": "in die Vergangenheit",
  "toFuture": "in die Zukunft",
  "imageWithLicenseImg": "***Bild:***",
  "dragNdropHint": "Drag-n-drop einer Datei oder Klick, um Bild zuzufügen",
  "maxFileSize": "Maximale Dateigröße:",
  "info": "Info",
  "attention": "Achtung",
  "articleOnWikipedia": "Artikel auf Wikipedia",
  "wikidataRecord": "Wikidata-Datensatz",
  "showLocationOnGoogleMaps": "Ort auf Google-Maps anzeigen",
  "showStoriesNearby": "Weitere hiStories in der Nähe",
  "showStoriesAtThatTime": "Was war noch zu dieser Zeit?",
  "showVideosOnYoutube": "Videos auf YouTube",
  "showBooksOnAmazon": "Bücher bei Amazon",
  "showDetails": "Details anzeigen",
  "details": "Details",

  "now": "jetzt",
  "closeWithSave": "Vor dem Schließen speichern?",
  "closeWithoutSaveQuestion": () => (<>Klicke auf 'Ja' und speichere deine Änderungen.<br/>Andernfalls gehen deine geänderten Daten verloren.</>),
  "seconds": "Sekunden",
  "minutes": "Minuten",
  "hours": "Stunden",
  "days": "Tage",
  "untilNow": "bis jetzt",
  "secondsUntilNow": "Sekunden bis jetzt",
  "minutesUntilNow": "Minuten bis jetzt",
  "hoursUntilNow": "Stunden bis jetzt",
  "daysUntilNow": "Tage bis jetzt",
  "unknownDuration": "unbekannte Dauer",
  "oClock": "Uhr",
  "pastOrFutureDuration": (isPast, duration) => ((isPast ? "vor " : "in ") + duration),
  "startType": "Art des Starts",
  "endType": "Art des Endes",
  "pointInTime": "Zeitpunkt",
  "born": "geboren",
  "earliest": "frühestens",
  "start": "Start",
  "origin": () => (<span><b>Entstehung</b><br/><Typography variant="caption">oder Gründung/Erstellung</Typography></span>),
  "discovery": "Entdeckung",
  "publication": "Publikation",
  "firstDescription": "Erstbeschreibung",
  "baptized": "getauft",
  "premiere": "Uraufführung",
  "opened": "eröffnet",
  "activeSince": "aktiv seit",
  "died": "gestorben",
  "latest": "spätestens",
  "end": "Ende",
  "decommissioning": "Stilllegung",
  "dissolved": "aufgelöst",
  "lost": "verschollen seit",
  "activeUntil": "aktiv bis",
  "discontinued": "eingestellt",
  "closed": "geschlossen",
  "precision": "Genauigkeit",
  "1000000000years": "1 Mrd Jahre",
  "100000000years": "100 Mio Jahre",
  "10000000years": "10 Mio Jahre",
  "1000000years": "1 Mio Jahre",
  "100000years": "100 Tsd Jahre",
  "10000years": "10 Tsd Jahre",
  "millenium": "Jahrtausend",
  "century": "Jahrhundert",
  "decade": "Jahrzehnt",
  "year": "Jahr",
  "month": "Monat",
  "day": "Tag",
  "hour": "Stunde",
  "minute": "Minute",
  "exact": "exakt",
  "duration": "Dauer",
  "years": "Jahre",
  "mon.": "Mon.",
  "h.": "Std.",
  "min.": "Min.",
  "noTimeChosen": "Kein Datum ausgewählt",
  "today": "heute",
  "weekdays": () => (["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]),
  "internalServerError": () => (<div>Es ist ein interner Serverfehler aufgetreten.<br/>Bitte setze dich mit einem Ansprechpartner von histomania in Verbindung.</div>),
  "userNotAcknowledged": () => (<div>Der Benutzer ist in unserem System vorhanden, er wurde aber noch nicht von Ihnen über den Link in der E-Mail bestätigt.</div>),
  "userOrPwdNotInSystem": () => (<div>Der Benutzer oder das eingegebene Passwort sind uns nicht bekannt.</div>),
  "oldPasswordUnknown": () => (<div>Das alte Kennwort ist uns nicht bekannt.</div>),
  "pwdGuideline": () => (<div>Die eingegebenen Daten entsprechen nicht den Richtlinien.</div>),
  "noDataRefresh": () => (<div>Daten konnten nicht aktualisiert werden.</div>),
  "emailAlreadyAvailable": () => (<div>Die eingegebene E-Mailadresse ist bereits vorhanden</div>),
  "userNotCreated": () => (<div>Der Benutzer konnte nicht angelegt werden</div>),
  "reCaptchaError": () => (<div>Google reCaptcha: Du konntest nicht als Mensch identifiziert werden.</div>),
  "notPlausible": () => (<div>Die Eingaben sind nicht plausibel.</div>),
  "sessionInvalid": () => (<div>Die Sitzung ist nicht mehr gültig.<br/>Bitte melde dich erneut an.</div>),
  "unknownError": (errorCode) => (<div>Es ist ein unbekannter Fehler mit der Nummer {errorCode} aufgetreten.<br/>Bitte setze dich mit einem Ansprechpartner von histomania in Verbindung.</div>),
  "hideStory": () => (<><Typography color="inherit">hiStory ausblenden</Typography>
    <hr/>
    Du findest diese hiStory nach dem Ausblenden schnell wieder mit der Suche unter <br/><em>'vor kurzem gesehen'</em>.</>),
  "showStory": () => (<><Typography color="inherit">hiStory anzeigen</Typography>
    <hr/>
    Die hiStory in einem neuen Tab öffnen und die Timeline parallel zu bestehenden Timelines hinzufügen.</>),
  "showStory2": "hiStory anzeigen",
  "restrictYourSearch": (totalcnt) => ("Zu viele Ergebnisse (" + totalcnt + "). Bitte schränke die Suchanfrage weiter ein."),
  "allowGeoLocation": "Bitte erlaube zunächst in den Einstellungen, dass histomania auf deinen Standort zugreifen darf.",
  "everytime": "jederzeit",
  "everywhere": "überall",
  "myNeighbourhood": "in meiner Nähe",
  "inNeighbourhood": "in der Nähe von",
  "search2": "Suche",
  "currentView": () => (<>aktuelle<br/>Ansicht</>),
  "recentlySeen2": () => (<>vor kurzem<br/>gesehen</>),
  "myStories2": () => (<>meine<br/>hiStories</>),
  "searchPhrase": "Suchbegriffe",
  "inputSearchPhrase": "Suchbegriff eingeben",
  "searchPhraseSample": "z.B.: Physiker Albert",
  "minimumCharacters": (minSearchWordCharCnt) => ("mindestens " + minSearchWordCharCnt + " Zeichen pro Suchbegriff"),
  "when": "Ereigniszeitraum",
  "where": "Ort",
  "inNeighbourhoodOf": "in der Nähe von ...",
  "chosenPosition": "gewählte Position",
  "latitude": "Breitengrad",
  "longitude": "Längengrad",
  "searchTimelines": "hiStories suchen",
  "searchStoriesInNeighbourhood": "Suche nach hiStories in der Nähe",
  "resultsFromLocalSearch": "Ereignisse aus der lokalen Suche (anklicken für Details):",
  "recommended": "Empfohlene hiStories",
  "todaysBirthdays": "Geburtstage heute",
  "todaysDeathdays": "Todestage heute",
  "anniversaries": "Jahrestage von Ereignissen heute",
  "hideAll": "Alle ausblenden",
  "inCurrentView": "in aktueller Ansicht",
  "privateTimeline": "private hiStory",
  "less": "weniger...",
  "image": "Bild",

  "logoURL": "https://" + window.location.host,
  "wikiLicenseCCSA": (site) => (<>Quelle: de.wikipedia.org - Der Text ist unter der Lizenz <a target="_blank" rel="noopener noreferrer" href={"https://de.wikipedia.org/wiki/Wikipedia:Lizenzbestimmungen_Creative_Commons_Attribution-ShareAlike_3.0_Unported"}>„Creative Commons Attribution/Share Alike“</a> verfügbar.<br/>Hier findest du die Liste der <a target="_blank" rel="noopener noreferrer" href={"https://xtools.wmflabs.org/articleinfo-authorship/de.wikipedia.org/" + site + "?uselang=de"}>Autoren</a>.</>),
  "wikiURL": "https://de.wikipedia.org/wiki/",

  "infiniteCalendarHeaderFormat": "dddd, D MMM",

  "resetfilter": "Filter zurücksetzen",
  "maintainEventList": "Ereignisse tabellarisch pflegen",

  "importcsv": "Von CSV importieren",
  "import": "Importieren",

  "prenameError": "Bitte gib deinen Vornamen ohne Ziffern und Sonderzeichen ein. Ein Vorname muss mindestens zwei Zeichen lang sein.",
  "surnameError": "Bitte gib deinen Nachnamen ohne Ziffern und Sonderzeichen ein. Ein Nachname muss mindestens zwei Zeichen lang sein.",
  "mailError": "Bitte gib eine gültige E-Mailadresse an, damit wir dir einen Bestätigunglink schicken können.",

  "fonts": "Schriftarten...",
  "bold": "fettgedruckt",
  "italic": "kursiv",
  "underline": "unterstrichen",
  "code": "Code",
  "unorderedList": "ungeordnete Liste",
  "orderedList": "geordnete Liste",
  "quote": "Zitat",
  "codeblock": "Codeblock",
  "divider": "Horizontaler Trennstrich",
  "alignment": "Textausrichtung...",
  "addImage": "Bild hinzufügen",
  "addVideo": "Video hinzufügen",
  "addMap": "Landkarte hinzufügen",
  "addPanorama": "360°-Ansicht hinzufügen",
  "addQuiz": "Quiz hinzufügen",
  "addInfoblock": "Info-Block hinzufügen",
  "addLink": "markierten Text mit Website verlinken",
  "addEvent": "markierten Text mit Ereignis in Timeline verlinken",
  "addStory": "markierten Text mit hiStory verlinken",
  "addFilter": "markierten Text mit Filter verlinken",

  "landingpage_title": "Die Plattform für Geschichte am Zeitstrahl",

  "welcome.js735054413": "Entdecke die Welt der Geschichte in hiStories, der interaktiven und multimedialen Zusammenstellung von Timelines, Texten, Bildern, Videos, Quiz, 360° Ansichten und Landkarten.",
  "welcome.js142706916": "Gestalte eigene Timelines für deinen Lebenslauf, eine Ortschronik oder eine Lernunterstützung zu einem unvergesslichen Erlebnis.",
  "welcome.js737110502": "in meiner Umgebung",
  "welcome.js455445377": "durchstöbern",
  "welcome.js695746560": "Entdecke die Welt der Geschichte in hiStories, der interaktiven und multimedialen Zusammenstellung von Timelines, Texten, Bildern und Quiz.",
  "welcome.js152384233": "Gestalte mit deinen Klassenkameraden oder Studienkollegen eigene Timelines und lerne geschichtliche Abläufe zu verstehen.",
  "explanation": () => (<>
    Erlebe Geschichte auf eine neue Art und Weise mit histomania, einer interaktiven Plattform, die es dir einfach macht, Geschichten zu erzählen und zu verstehen.
    <br/>
    <br/>
    Verliere die Zeit und den Ort niemals aus den Augen, während du durch tausende von Geschichten und Biografien navigierst.
    <br/>
    Im Gegensatz zu klassischen Hypertext-Erzählungen, bei denen man nur von einem Artikel zum nächsten springen kann, enthalten die Texte in histomania zusätzliche Links, die es ermöglichen, Ereignisse auf einem Zeitstrahl oder einer Landkarte zu verfolgen.
    <br/>
    <br/>
    <PostAddIcon/>&nbsp;&nbsp;öffnet eine neue hiStory
    <br/>
    <ScheduleIcon/>&nbsp;&nbsp;zeigt dir das Ereignis im Zeitstrahl
    <br/>
    <RoomIcon/>&nbsp;&nbsp;zeigt dir die Position des Ereignisses in der Karte
    <br/>
    <Filter/>&nbsp;&nbsp;filtert Ereignisse in der Timeline und der Chronologie
    <br/>
    <br/>
    Erforsche die Größenordnungen von Zeiträumen, indem du Zeitstrahlen verschiedener hiStories miteinander vergleichst und die Abstände zwischen Ereignissen innerhalb dieser Zeitstrahlen vermisst.
    <br/>
    Durch Vergleich und Vermessung wird es dir möglich, die Zeiträume und Epochen der Geschichte auf eine neue Art und Weise zu erschließen.
    <br/>
    <br/>
    Nutze interaktive Karten, die neben Pins für Positionsmarkierungen auch Pfeilketten für Reiserouten und Overlays zur Darstellung von Gebieten enthalten können.
    <br/>
    Diese können sogar animiert sein und eine von dir gewählte Transparenzstufe haben, was dir ein einzigartiges Verständnis von Grenzveränderungen ermöglicht.
    <br/>
    <br/>
    Entdecke Geschichten in deiner aktuellen Umgebung oder wenn du in einer fremden Stadt unterwegs bist einfach auf Knopfdruck.
    Finde die passenden Geschichten in Museen über einen QR-Code.
    <br/>
    <br/>
    Du kannst sogar deine liebsten hiStories in deine eigene Homepage einbetten.
    <br/>
    <br/>
    Egal, ob du eigene hiStories veröffentlichen oder nur durch die Geschichte surfen möchtest, histomania ist der ideale Begleiter für Schüler im Geschichtsunterricht, Studenten der Geschichtswissenschaft, Historiker, Ortschronisten und alle, die einfach Spaß an Geschichte oder aktuellen Ereignissen in Politik und Weltgeschehen haben.
  </>),
  "topfacts_wiki": () => (<span>Gibt es zu einer Geschichte, oder einem Ereignis in der Geschichte einen entsprechenden Artikel auf Wikipedia, oder Videos auf YouTube?<br/>Oder falls ein Ereignis örtlich bestimmt ist, möchtest du es auf einer Google Maps Karte ansehen, und dich über diese ggf. gleich dort hin navigieren lassen?<br/>Mit einem Klick landest du von den Details in histomania auf der entsprechenden Seite.</span>),
  "topfacts.js415020171": "surfen",
  "topfacts.js111683501": "vergleichen",
  "topfacts.js56475656": "integrieren",
  "topfacts.js939157228": "kostenlos",
  "topfacts.js617723463": "messen",
  "topfacts.js692398414": "erstellen",
  "topfacts.js899063949": "fiktive Fragen",
  "topfacts.js152860411": "teilen",
  "topfacts.js55830277": "verlinkt",
  "topfacts.js335278155": "suchen",
  "topfacts.js150418448": "geräteunabhängig",
  "topfacts.js820645511": "vom Urknall bis zum Weltuntergang",
  "topfacts.js645936163": "Durch die Verlinkung von geschichtlichen Ereignissen kannst du von einer Geschichte zur anderen navigieren.",
  "topfacts.js751322491": "histomania ermöglicht es dir, mehrere Zeitstrahlen untereinander zu legen, und somit vergleichbar zu machen.",
  "topfacts.js921529656": "Du möchtest Geschichten in deine eigene Webseite oder deinen Blog einbinden? Erfahre hier mehr.",
  "topfacts.js895080977": "histomania ist in der Basisversion kostenlos. Egal, ob du es für private, kommerzielle, oder Bildungszwecke einsetzt.",
  "topfacts.js948823105": "Wie viele Jahre ist Marilyn Monroe älter als James Dean? Wie alt war Einstein, als er den Nobelpreis bekam? Miss die Zeiten, die dich auf dem Zeitstrahl interessieren, einfach per integriertem Messschieber.",
  "topfacts.js107256625": "Schreib deine eigene Geschichte. Stelle geschichtliche Ereignisse so dar, dass das gezeigt wird, was dir wichtig ist. Oder beschreibe Deinen Lebenslauf auf einem Zeitstrahl.",
  "topfacts.js102762366": "Was wäre wenn... du beispielsweise zur gleichen Zeit wie Goethe geboren worden wärst? Erstelle deinen Lebenslauf, und verschiebe ihn so, dass du ihn mit dem von anderen vergleichen kannst.",
  "topfacts.js373990748": "Arbeite gemeinsam mit anderen auf einem Zeitstrahl und bestimme über Berechtigungen, wer deine Geschichte sehen oder bearbeiten darf.",
  "topfacts.js809530473": "Neben der Suche von Schlagwörtern kannst du auch danach suchen, was in bestimmten Zeiträumen an bestimmten Orten geschehen ist. Bist du an einem fremden Ort und möchtest mehr über die Geschichte der Gegend erfahren? Klicke auf 'Was ist in der Nähe?' und suche mit einem GPS-fähigen Smartphone nach Ereignissen im Umkreis.",
  "topfacts.js15371141": "Ob auf einem Smartphone, einem Tablet oder dem PC. Mit histomania hast du eine Anwendung die sich auf alle Geräte anpasst, sei es auf die Bildschirmgröße, oder auf die Art der Eingabe per Maus oder Touch-Gesten.",
  "topfacts.js678201177": "histomania kann unglaublich große Zeiträume mit einstellbarer, bis zu minutengenauer Präzision, verarbeiten. Somit können sowohl die Epochen der Erdgeschichte, als auch die Geschichte der ersten Mondlandung sinnvoll dargestellt werden.",
  "contact": "Kontakt",
  "phone": "Telefon",
  "newHiStoryMessage": () => (<span>Deine neue hiStory ist jetzt erstellt.<br/>Füge nun Ereignisse hinzu.</span>),
  "histomaniamain.js218992075": "Bitte mindestens einen Administrator angeben.",
  "menu.js844059924": "Neue hiStory",
  "storydetailsmain.js785302558": "neue hiStory",
   "storydetailsmain.js382589702": "hiStory",
  "mainFab.js309680386": 'hiStory bearbeiten',
  "mainFab.js593675655": 'Ereignisse in Tabelle pflegen',
  "mainFab.js968262525": 'Ereignis hinzufügen',
  "mainFab.js943257132": 'neue hiStory',
  "bookmarks.js93045028": "Lesezeichen",
  "menu.js505082777": "Neues Ereignis",
  "menu.js756551371": "Was wäre wenn...?",
  "menu.js663038438": "Was wäre, wenn... rückgängig machen",
  "menu.js439620799": "Lesezeichen",
  "menu.js157014000": "Drucken und Bildexport",
  "menu.js555525792": "Import/Export",
  "menu.js923812869": "Profilverwaltung",
  "menu.js450602741": "Mein Profil",
  "menu.js984392851": "Passwort ändern",
  "menu.js498764961": "Sprache",
  "menu.js869701443": "Deutsch",
  "menu.js503793771": "Englisch",
  "menu.js161223496": "Ukrainisch",
  "menu.js961199351": "Meine Einstellungen",
  "menu.js165576261": "Abmelden",
  "menu.js972296370": "Anmelden",
  "bargroup.js313011152": "Bezeichnung",
  "bargroup.js999078620": "neue Bezeichnung",
  "barshape.js677799618": "fetter Balken",
  "barshape.js210728036": "schmaler Balken",
  "barshape.js708427194": "geschweifte Klammer",
  "barshape.js853386386": "transparenter Hintergrund",
  "barshape.js709767519": "kleiner Stern",
  "barshape.js620435244": "Stern",
  "barshape.js170641551": "kleiner Kreis",
  "barshape.js649456610": "Kreis",
  "barshape.js494726977": "Wolke",
  "barshape.js735933567": "Sprechblase",
  "barshape.js931763133": "Text mittig",
  "barshape.js931763134": "Nur Grundlinie",
  "barshape.js509994468": "kleines Dokument",
  "barshape.js848987645": "Dokument",
  "barshape.js786118528": "kleine Sonne",
  "barshape.js560723628": "Sonne",
  "barshape.js624588809": "kleines Kreuz",
  "barshape.js33818872": "Kreuz",
  "barshape.js597772577": "kleiner Pfeil links",
  "barshape.js170062957": "Pfeil links",
  "barshape.js731660847": "kleiner Pfeil rechts",
  "barshape.js233359132": "Pfeil rechts",
  "dataset.js729839193": "Fehler in der Beschreibung",
  "dataset.js345733057": "Bezeichnung",
  "dataset.js850812704": "Einheit",
  "descriptionBlock.js240405443": "Beschreibung",
  "descriptionBlock.js884402762": "Hier kannst du auch Bilder und Videos einfügen.",
  "designBlock.js426292766": "Gestaltung",
  "designBlock.js902377753": "So stellt sich das Ereignis in der Timeline dar.",
  "imageBlock.js428756050": "Bild",
  "imageBlock.js604367201": "Dieses Bild erscheint in der Timeline. Weitere Bilder kannst du in der Beschreibung einfügen.",
  "imageBlock.js399000806": "Bildlizenz",
  "imagechooser.js38403923": "Bildlizenz",
  "innerevents.js238036015": "Innere Zeiten",
  "sourceReferenceBlock.js99152413": "Quellenangaben",
  "sourceReferenceBlock.js484508229": "Liste hier die Quelle(n) auf, die das Ereignis belegen.",
  "timeBlock.js186865327": "Zeit",
  "timeBlock.js929675741": "Setze für vorchristliche Zeiten ein Minus vor das Jahr, z.B. 21.04.-753",
  "timeBlock.js910792715": "Zeitpunkt",
  "timeBlock.js134397445": "Zeitraum",
  "titleBlock.js239194644": "Titel",
  "titleBlock.js47802846": "Du kannst mehrere Zeilen verwenden.",
  "wikipediareference.js851003152": "deutsche Wikipediaseite",
  "bookmarkitem.js608408822": "Löschen",
  "story.js915248213": "Meine hiStory",
  "drafteditortoolbar.js858681026": "Schriftart",
  "drafteditortoolbar.js823346092": "Absatz",
  "drafteditortoolbar.js97337207": "Medien",
  "drafteditortoolbar.js82711167": "Links",
  "chooseImageDialog.js542504966": "Titel",
  "chooseMapDialog.js901660970": "Bezeichnung der Karte",
  "chooseMapDialog.js589922616": "Bezeichnung",
  "chooseMapDialog.js87695793": "Google Maps URL",
  "chooseMapDialog.js10384243": "Bildbeschreibung und Lizenz",
  "chooseStreetViewDialog.js854616579": "Titel",
  "chooseStreetViewDialog.js119725327": "Streetview embed code",
  "chooseVideoDialog.js778434054": "Titel",
  "chooseVideoDialog.js671606307": "YouTube-URL oder ID",
  "chooseVideoDialog.js799772333": "Start bei Sekunde",
  "AddVideoButton.js665672521": "Einen Zeitstrahl kostenlos erstellen",
  "maintenanceEventList.js998639033": "Ereignisse",
  "filtertimeline.js710501750": "Ereignisse filtern",
  "searchfield.js825023426": "hiStories nach diesem Begriff durchsuchen",
  "searchfield.js18900516": "hiStories in meiner Umgebung",
  "searchfield.js363834300": "hiStories nach Zeit und Ort suchen",
  "searchfield.js644144191": "Meine hiStories",
  "searchfield.js660560315": "Vor kurzem gesehene hiStories",
  "searchfield.js961730789": "Aktuelle Timeline mit diesem Begriff filtern",
  "searchfield.js867502466": "Aktuelle Timeline filtern",
  "searchfield.js829902906": "Suchvorschläge",
  "histomaniamain.js781485964": "Mein Ereignis",
  "histomaniamain.js702299662": "Bitte öffne oder erstelle zunächst eine hiStory um das Ereignis anzulegen.",
  "histomaniamain.js197615179": "Vorsicht beim Teilen deiner URL.\nDeine hiStory ist privat und kann nicht von allen Empfängern gesehen werden.",
  "histomaniamain.js12025145": "Bitte gib einen Titel mit mindestens drei Buchstaben für deine hiStory an.",
  "histomaniamain.js310062970": "Um die hiStory längere Zeit zu speichern musst du dich anmelden.",
  "histomaniamain.js5248846": "Bitte öffne zunächst eine hiStory, die du bearbeiten darfst, um das Ereignis dort hin zu kopieren.",
  "histomaniamain.js513585760": "springe zu",
  "import.js261569334": "Bitte öffne zunächst eine hiStory um die Ereignisse dort abzulegen.",
  "import.js67411235": "Wähle die hiStory, in die die Ereignisse abgelegt werden sollen.",
  "login.js758426829": "Melde dich an",
  "login.js779088926": "E-Mail",
  "login.js163307733": "Passwort",
  "login.js293038552": "angemeldet bleiben",
  "login.js116720706": "Noch kein Konto?",
  "login.js319559879": "Vorname",
  "login.js278946069": "Bitte einen Vornamen angeben",
  "login.js897560837": "Nachname",
  "login.js237687098": "Bitte einen Nachnamen angeben",
  "login.js457756656": "E-Mail",
  "login.js692708124": "Bitte eine gültige E-Mailadresse angeben",
  "login.js368777066": "Passwort vergessen?",
  "login.js327950210": "E-Mail",
  "login.js493155713": "Anmeldung",
  "editMediaDialog.js254758074": "Medienliste",
  "editMediaDialog.js106456795": "JSON",
  "markdowneditfield.js110445871": "Panoramaansicht bearbeiten",
  "markdowneditfield.js117272661": "Quiz bearbeiten",
  "markdowneditfield.js848013488": "Medienelement bearbeiten",
  "lazygooglemap.js947359452": "Ich bin damit einverstanden, dass mir Inhalte von Google-Maps angezeigt werden. Hierdurch können personenbezogene Daten an Dritte übermittelt werden.",
  "QuizEditorTitlePage.js823814415": "Titel",
  "menu.js893403567": "Teilen",
  "menu.js932778276": "als E-Mail",
  "menu.js556545983": "Daten exportieren",
  "menu.js413767225": "Daten importieren",
  "menu.js990897862": "Hilfe",
  "menu.js171939370": "von ",
  "print.js570625276": "Die Mindestbreite beträgt ",
  "print.js612051645": "Die Maximalbreite beträgt ",
  "print.js574630162": "Die minimale Balkenhöhe beträgt ",
  "print.js760791437": "Die maximale Balkenhöhe beträgt ",
  "print.js758964558": "Der maximale Überstand wurde überschritten.",
  "print.js375853243": "Der Überstand darf nicht negativ sein.",
  "print.js15990392": "Es muss mindestens ein horizontaler Teil zum Drucken vorhanden sein.",
  "print.js556329669": "Es muss mindestens ein vertikaler Teil zum Drucken vorhanden sein.",
  "print.js833055774": "Das Bild darf nicht in mehr als 100 Teile geschnitten werden.",
  "printdialog.js85755474": "Überstand wegen Beschriftung (",
  "printdialog.js341020472": "transparenter Hintergrund",
  "printdialog.js665169354": "Schatten zeichnen",
  "printdialog.js605134089": "horizontal",
  "printdialog.js839026585": "vertical",
  "displaystorydetails.js158045992": "Quellen",
  "addstorydescriptor.js92624992": "Eingebettete hiStories",
  "addstorydescriptoreditdialog.js502574362": "Gruppe",
  "addstorydescriptoreditdialog.js124818429": "ID der hiStory",
  "addstorydescriptoreditdialog.js117860561": "ungefilterte Ereignis-IDs",
  "addstorydescriptoreditdialog.js777122119": "Position",
  "backgroundimageinput.js816937029": "Bild",
  "backgroundimageinput.js218259721": "Dieses Bild dient als Hintergrund der Timeline und erscheint in der Suche. Weitere Bilder kannst du in der Beschreibung einfügen.",
  "backgroundimageinput.js429573179": "Bildlizenz",
  "userrightsdialog.js70424287": "Neue Berechtigung",
  "userrightsdialog.js480394996": "E-Mail",
  "userrightsdialog.js216158241": "Bitte eine gültige E-Mailadresse angeben",
  "bookingrightsadministration.js589033383": "Berechtigungen",
  "bookingrightsadministration.js546709873": "jeder darf die hiStory lesen",
  "descriptioninput.js517543907": "Beschreibung",
  "dummyuserhint.js499234330": "Du bist nicht angemeldet",
  "headlineinput.js150642075": "Überschrift",
  "headlineinput.js593049979": "Titel der hiStory",
  "headlineinput.js477282705": "Subtitel",
  "newstoryhint.js799008560": "Erste Schritte",
  "sourcereferenceinput.js783166353": "Quellenangaben",
  "wikipediainput.js705594377": "Erweiterte Inhalte",
  "search.js726564732": "Suche",
  "search.js671154469": "jederzeit",
  "durationpanel.js274332505": "unbekannte Dauer",
  "searchfield.js807364549": "In aktueller Timeline suchen",
  "histomaniamain.js734544182": "Die hiStory wird nur für deine Sitzungsdauer gespeichert.",
  "menu.js698756284": "Eventuell bisher testweise gemachte Änderungen werden zurückgesetzt",
  "userrightsdialog.js937814759": "Berechtigung bearbeiten",
  "bargroup.js45524065": "Bezeichnung überall ändern",
  "sourceReferenceBlock.js304249147": "Quellenangaben für das Ereignis angeben.",
  "timeBlock.js20893151": "Innere Zeiten...",
  "wikipediareference.js907588328": "Ich möchte einen Wikipedia-Artikel einbetten.",
  "bookmarkdialog.js412170740": "Lesezeichen zufügen",
  "bookmarkdialog.js929413458": "Du bist nicht angemeldet. Deine Lesezeichen bleiben nur für etwa einen Tag gültig.",
  "bookmarks.js475765892": "offene Timelines als Lesezeichen speichern",
  "bookmarks.js306859711": "Wirklich löschen?",
  "cookieconsent.js905029185": "Wir verwenden Cookies und localStorage-Einträge. Deine Einstellungen kannst du jederzeit über das Menü in der App anpassen. Wenn du nicht alle Cookies oder localStorage-Einträge akzeptieren möchtest, oder du mehr darüber erfahren willst, dann klicke auf 'Mehr anzeigen'.",
  "cookieconsent.js352836975": "Mehr anzeigen",
  "cookieconsent.js243259253": "Schließen",
  "cookieconsent.js645797755": "Alle Cookies annehmen",
  "schoolcookieconsent.js905029185": () => <>Wir verwenden auf schule.histomania.com nur technisch notwendige Cookies und localStorage-Einträge, die nicht abgelehnt werden könnnen.<br/>Hierzu zählen ein Sessioncookie und localStorage-Einträge, die die Sprachauswahl, Kennzeichen für den Benutzer, damit er sich nicht wiederholt anmelden muss, sowie das Bestätigen dieses Fensters speichern.<br/></>,
  "schoolcookieconsent.js645797755": "OK",
  "drafteditortoolbar.js747935826": "Schriftart",
  "drafteditortoolbar.js616605922": "Absatz",
  "drafteditortoolbar.js894699961": "Medien",
  "drafteditortoolbar.js109669568": "Links",
  "chooseMapDialog.js417538297": "Schritt 2:",
  "chooseMapDialog.js561133791": "Schritt 3:",
  "chooseMapDialog.js61491380": "übernehmen",
  "Link.js291014714": "hiStory öffnen",
  "Link.js62298905": "Ereignisse in Timeline filtern",
  "maintenanceeventlisttable.js380608930": "Bezeichnung",
  "maintenanceeventlisttable.js866297000": "Start",
  "maintenanceeventlisttable.js573824846": "Ende",
  "filtertasklist.js930196063": "Alle",
  "filtertasklist.js18265885": "Alle Ereignisse",
  "filtertasklist.js560877919": "Begriff",
  "filtertasklist.js132146692": "Zeitraum",
  "filtertasklist.js8240783": "Eigenschaften",
  "filtertasklist.js192609356": "Zeitpunktereignisse",
  "filtertasklist.js774361819": "Zeitraumereignisse",
  "filtertasklist.js700792519": "Gruppen",
  "filtertasklist.js482827431": "Einzelereignisse",
  "extendedmenu.js908817077": "Drucken und Bildexport",
  "extendedmenu.js336969410": "Lesezeichen",
  "extendedmenu.js82030918": "Einstellungen",
  "extendedmenu.js7002811": "Hilfe",
  "extendedmenu.js598943965": "Sprache",
  "extendedmenu.js760560118": "Home",
  "histomaniamain.js114097156": "Komplette Timeline in sichtbaren Bereich",
  "import.js191606136": "CSV-Import",
  "import.js112638704": "Kopiere den Inhalt deiner CSV-Datei in das Textfeld und klicke auf 'Import'.",
  "login.js382006560": "Abonniere auch gleich den histomania-Newsletter.",
  "login.js416865926": "Nutzungsbedingungen",
  "login.js64439934": "Datenschutzerklärung",
  "login.js572661779": "Ich habe die Nutzungsbedingungen und die Datenschutzerklärung gelesen und akzeptiere diese",
  "login.js230957075": "Konto erstellen",
  "login.js878212959": "Neues Passwort zusenden",
  "editMediaDialog.js808123558": "Medien",
  "markdowneditfield.js554353551": "Schriftarten",
  "markdowneditfield.js558691581": "Überschrift 1",
  "markdowneditfield.js785963602": "Überschrift 2",
  "markdowneditfield.js935699377": "Überschrift 3",
  "markdowneditfield.js534085527": "Überschrift 4",
  "markdowneditfield.js518723970": "Überschrift 5",
  "markdowneditfield.js452157683": "Überschrift 6",
  "markdowneditfield.js267080676": "Kursiv (selektierter Text)",
  "markdowneditfield.js267174421": "Fett Kursiv (selektierter Text)",
  "markdownhelper.js160663836": "Medienelement konnte nicht gefunden werden",
  "markdownhelper.js744897963": "Filter",
  "markdownhelper.js297030165": "Ort anzeigen",
  "markdownhelper.js702490738": "externe Webseite öffnen",
  "quizErrorDialog.js818208140": "Es ist ein Titel für das Quiz erforderlich.",
  "quizErrorDialog.js316989684": "Bitte gib mindestens eine Frage an.",
  "quizErrorDialog.js217047946": "Frage #{index+1}: Gib die Frage an.",
  "quizErrorDialog.js592184179": "Frage #{index+1}: Gib höchstens vier Antworten an.",
  "quizErrorDialog.js91808612": "Frage #{index+1}: Markiere mindestens eine korrekte Antwort über die Checkboxen.",
  "quizErrorDialog.js770785722": "Frage #{index+1}: Gib mindestens eine Antwort an.",
  "quizErrorDialog.js121149031": "Frage #{index+1}, Antwort #{answerIndex+1}: Eine leere Antwort ist nicht erlaubt.",
  "lazygooglemap.js821091857": "Leider können dir in dieser Subdomain aus datenschutzrechtlichen Gründen keine Inhalte von Google-Maps angezeigt werden.",
  "media.js334165907": "Fehler in der Kartenbeschreibung",
  "media.js745432641": "Fehler in der Kartenbeschreibung",
  "QuizEditorTitlePage.js567873608": "Startseite",
  "QuizEditorTitlePage.js3575109": "Beschreibung",
  "QuizQuestion.js522602069": "Formuliere hier deine Frage",
  "QuizQuestion.js277305215": "Füge Antworten hinzu und setze ein Häkchen bei den korrekten Antworten",
  "QuizQuestion.js719954254": "Hier kann eine Erklärung der Antwort angefügt werden",
  "QuizQuestion.js631196400": "So viele Punkte soll es bei einer richtigen Antwort geben",
  "streetview.js903524730": "Leider können dir in dieser Subdomain aus datenschutzrechtlichen Gründen keine Inhalte von Google-Maps angezeigt werden.",
  "youtube.js541386012": "Leider können dir in dieser Subdomain aus datenschutzrechtlichen Gründen keine Inhalte von YouTube angezeigt werden.",
  "circularmenu.js381995722": "Suchen",
  "circularmenu.js524039849": "Zeitabstände in der Timeline messen",
  "circularmenu.js739065959": "Benutzerdefinierte Einstellungen",
  "circularmenu.js357509198": "Lesezeichen",
  "circularmenu.js636940232": "Alle Gruppen ein-/ausklappen",
  "circularmenu.js355370525": "Eine neue hiStory erstellen",
  "circularmenu.js753068960": "Ein Zeitpunkt-Ereignis erstellen",
  "circularmenu.js653781889": "Ein Zeitabschnitt-Ereignis erstellen",
  "menu.js826958696": "Datenschutzerklärung",
  "menu.js586813367": "AGB und Nutzungsbedingungen",
  "menu.js614038001": "Impressum",
  "menu.js439856996": "Bildlizenzen",
  "menu.js942699906": "Cookie-Einstellungen rücksetzen",
  "printdialog.js317202399": "Auflösung",
  "printdialog.js176250960": "Zeitbereich",
  "printdialog.js161420731": "Bildeinstellungen",
  "printdialog.js399246718": "Bild teilen",
  "printdialog.js150161449": "Vorschau",
  "printdialog.js8964786": "Drucken",
  "printdialog.js544885797": "Bildexport",
  "ratings.js661212453": "Um bewerten zu können musst du dich anmelden.",
  "displaystorydetails.js121722705": "Bildlizenzen anzeigen",
  "bookingrightsadministration.js131215424": "Diese Benutzer dürfen die hiStory bearbeiten:",
  "dummyuserhint.js706662830": "Ohne Anmeldung kannst du eine hiStory nur zu ",
  "newstoryhint.js223326167": () => (<>Hier erzählst du deine hiStory, die den Rahmen für deine Timeline bildet.<br/>Fürs Erste reicht es aber aus, wenn du deiner hiStory nur einen Titel gibst und dann speicherst.<br/>Danach kannst du mit der Eingabe der einzelnen Ereignisse beginnen.</>),
  "bookingrights.js286057285": "Die hiStory darf von ",
  "distributerightsdialog.js87761818": "Rechte verteilen",
  "barexpansion.js878339034": "Höhe",
  "barexpansion.js887101724": "-fach",
  "barexpansion.js728758094": "Lege die angezeigte Höhe des Ereignisses als ein Vielfaches der normalen Höhe fest.",
  "bargroup.js152857522": "Gruppe",
  "bargroup.js914347576": "Bezeichnung ändern",
  "barposition.js84416775": "Position",
  "barposition.js560624624": "Bestimme über die Position, welche Ereignisse weiter unten, und welche weiter oben angezeigt werden sollen.",
  "barsettings.js434654385": "Farbe",
  "barsettings.js408327828": "Wähle die Farbe für das Ereignis. Die Schriftfarbe wird automatisch ausgewählt.",
  "barshape.js315964835": "Form",
  "barshape.js230080019": "Wähle die Form des Ereignisbalkens.",
  "dataset.js205796920": "Diagrammdaten",
  "dataset.js666145317": "Beschreibe zeitabhängige Diagramme.",
  "dataset.js354018274": "Füge hier den CSV-Inhalt ein und drücke OK:",
  "dataset.js218289977": "Importieren",
  "editbookingdetails.js985514551": "Möchtest das Ereignis du wirklich löschen?",
  "imagechooser.js862610110": "Bild an Ereignis in Timeline",
  "imagechooser.js871519548": "Bestimme ein Bild, das in der Timeline erscheint.",
  "imagechooser.js950070103": "Weitere Bilder kannst du in der Beschreibung hinzufügen.",
  "wikipediareference.js272862577": "Wikipedia-Referenz",
  "wikipediareference.js904830442": "Verweis auf Wikipedia.",
  "bookmarks.js523603774": "Möchtest du das Lesezeichen '{deleteBookmarkS && deleteBookmarkS.name}' wirklich löschen?",
  "chooseMapDialog.js720327007": "Gib die Bezeichnung an.",
  "chooseMapDialog.js760709110": "Klicke dann in die Karte, um einen neuen Marker zu setzen, oder gib die URL von Google-Maps ein.",
  "chooseMapDialog.js388797867": "Gib die Bildbeschreibung und die Lizenz an, falls dein Bild nicht lizenzfrei ist.",
  "chooseMapDialog.js842057153": "Passe die Größe in der Karte an. Füge Kontrollpunkte (mindestens drei) mit [Ctrl + Click] hinzu.",
  "chooseMapDialog.js982829124": "Wähle dann ",
  "Link.js776621301": "Ereignis in Timeline",
  "Link.js501324063": "Eine weitere hiStory öffnen.",
  "import.js93314536": "Import von MS-Excel",
  "import.js254441848": "Import von Textfeld",
  "login.js15485281": "Bitte gib deine bei histomania hinterlegte E-Mail-Adresse ein:",
  "markdowneditfield.js426937653": "Fett (selektierter Text)",
  "markdowneditfield.js85888028": "Bild einfügen",
  "markdowneditfield.js249402737": "Video einfügen",
  "markdowneditfield.js421012641": "Karte einfügen",
  "markdowneditfield.js535534111": "Panoramaansicht einfügen",
  "markdowneditfield.js58232052": "Filter einfügen",
  "markdowneditfield.js954635745": "Quiz einfügen",
  "markdowneditfield.js827493202": "Liste aller Medien",
  "markdownhelper.js904359583": "Ereignisse in Chronologie und Timeline filtern.",
  "markdownhelper.js133555219": "Ereignis in Timeline finden",
  "markdownhelper.js499018470": "Ein Pfeil zeigt dir die Position des Ereignisses im Zeitstrahl.",
  "markdownhelper.js743956038": "Die Position des Ereignisses wird auf einer Karte angezeigt.",
  "markdownhelper.js813604085": "Ein neuer Browsertab wird mit dem Artikel geöffnet.",
  "addstorydescriptor.js556883219": "Eingebettete hiStories",
  "addstorydescriptor.js110462036": "neue eingebettete hiStory",
  "bookingrights.js50879320": "Melde dich an, um hiStories mit anderen zu teilen.",
  "admincomponent.js149123880": "Schließen",
  "loginbutton.js370395105": "anmelden",
  "mediaitemlistbutton.js846631503": "Liste aller Medien",
  "registerbutton.js180629076": "kostenlos registrieren",
  "hintdialog.js158836303": "Schließen",
  "barexpansion.js805637512": "normal",
  "barexpansion.js271938727": "25-fach",
  "barexpansion.js680468558": "50-fach",
  "barexpansion.js321153821": "75-fach",
  "barexpansion.js970143224": "100-fach",
  "barposition.js477225547": "ganz unten",
  "barposition.js21166688": "normal",
  "barposition.js400652493": "ganz oben",
  "bookingrightsadministration.js501993017": "Weiteren Benutzer berechtigen",
  "streetview.js459438593": "Ich bin damit einverstanden, dass mir Inhalte von Google-Maps angezeigt werden. Hierdurch können personenbezogene Daten an Dritte übermittelt werden.",
  "displaystorydetails.js463453345": "Ereignisse. Klicke einen Eintrag für Details oder das Uhrensymbol zum Finden im Zeitstrahl",
  "dataset.newLine": "neue Zeile",
  "dataset.fromCSV": "von csv",
  "markdowneditfield.name": "Name",
  "markdowneditfield.birth": "Geburtsdatum",
  "addmarkdownbutton.53645": "Dies ist eine Markdown-Überschrift",
  "timeblock.3457335": "Gib entweder die Dauer oder das Ende an, der andere Wert wird jeweils berechnet. Ohne Angabe geht das Ende bis zur aktuellen Zeit.",
  "innerevents.34353463": "Zur Zeit steht nur der JSON-Editor zur Eingabe zur Verfügung.",
  "bargroup.54345345": "Ereignisse der gleichen Gruppe werden in einem beschrifteten Block in der Timeline dargestellt.<br/>Gruppenbezeichnungen sind case-sensitiv, d.h. es wird zwischen Groß- und Kleinschreibung unterschieden.",
  "cookieconsent.0": "Bevor du fortfährst",
  "cookieconsent.1": "Technisch notwendige Cookies",
  "cookieconsent.2": () => <>Betriebstechnische Cookies können nicht deaktiviert werden, da ansonsten unsere Seite nicht mehr funktionieren kann.<br/>Wir verwenden beispielsweise Session-Cookies um die aktuelle Auswahl deiner hiStories zu speichern.<br/>Des Weiteren können in benutzergenerierten Inhalten Seiten von YouTube und Google-Maps eingebettet sein.<br/>Auch hierdurch können Cookies seitens der genannten Hersteller gesetzt werden. <br/>Wir fragen dich vor dem Laden dieser Inhalte noch einmal vorher ab, ob du dies möchtest.<br/>Deine Auswahl merken wir uns im Local Storage des Browsers.<br/><br/></>,
  "cookieconsent.3": "Analyse-Cookies",
  "cookieconsent.4": "Wir verwenden Google-Analytics um Statistiken zu erstellen. Dabei werden keine personenbezogenen Daten verarbeitet. IP-Adressen werden anonymisiert gespeichert (die letzten Stellen werden durch ein X ersetzt).",
  "cookieconsent.5": "Du kannst die Cookie und Local Storage Einstellungen jederzeit widerrufen, indem du im Menü 'Cookie-Einstellungen rücksetzen' wählst.",

  "enlargeToSelection": "auf Auswahl vergrößern",
  "fixDuration": "Dauer fixieren",
  "endMeasurement": "Messung beenden",
  "secondsFromNow": "Sekunden ab jetzt",
  "minutesFromNow": "Minuten ab jetzt",
  "hoursFromNow": "Stunden ab jetzt",
  "daysFromNow": "Tage ab jetzt",

  "chronology": "Chronologie",
  "languageinput.js593099979": "Sprache",

  "fontSettings.js878339034": "Schrift",
  "fontSettings.js887101724": "-fach",
  "fontSettings.js728758094": "Lege die Höhe der Beschriftung als ein Vielfaches der normalen Höhe fest.",
  "fontSettings.quarter": "1/4",
  "fontSettings.half": "1/2",
  "fontSettings.threequarters": "3/4",
  "fontSettings.full": "normal",
  "fontSettings.oneandahalf": "1,5-fach",
  "fontSettings.two": "doppelt",

  "fontSettings.transparency": "Undurchsichtigkeit",
  "fontSettings.height": "Höhe",
  "fontSettings.25percent": "25%",
  "fontSettings.50percent": "50%",
  "fontSettings.75percent": "75%",
  "fontSettings.100percent": "100%",
  "fontSettings.bold": "fett",
  "fontSettings.italic": "kursiv",

  "deleteProfile": "Mein Profil löschen",
  "deleteProfileContent": () => <>Das Löschen deines Profils löscht nicht deine hiStories.<br/>Diese bleiben ggf. für andere Benutzer unter einem anonymisierten Profil sichtbar.<br/>Möchtest du, dass deine hiStories nicht mehr für andere zugänglich sind, dann lösche diese, bevor du dein Profil löschst.<br/><br/>Möchtest du dein Profil unwiderruflich löschen?</>,

  "decorationdescriptor.js556883219": "Dekoration",
  "decorationdescriptor.js92624992": "Dekoration",
  "menu.mapForTimeInterval": "Zusammengefasste Karte aus Zeitintervall",
  "relation.js272862577" : "Verbindungslinien",
  "relation.js904830442" : "Verbindungslinien",

  "connection.target" : "Verknüpfungsziel",
  "connection.name" : "Bezeichnung",
  "connection.color" : "Farbe",
  "connection.width" : "Breite",
  "connection.textpos" : "Textposition",
  "connection.startpos" : "Startposition",
  "connection.endpos" : "Endposition",

  "schoolclass6" : "6",
  "schoolclass7" : "7",
  "schoolclass8" : "8",
  "schoolclass9" : "9",
  "schoolclass10" : "10",
  "schoolclass11" : "11",
  "schoolclass12" : "12",
  "schoolclass13" : "13",

  "curriculum.title" : "hiStories nach Schulklassen",
  "curriculum.text" : "Hier findest du ausgewählte hiStories nach Schulklassen geordnet. Da jedes Bundesland seinen eigenen Lehrplan hat, ist die Einteilung nur grob möglich.",

  "welcome.about" : "Über histomania"
}


export default res
