class BaseConfig {

    static getResBaseURL() {
        if(window.location.href.includes("localhost:3000") || window.location.href.includes("peter-thinkpad:3000")) {
            return '/';
        } else {
            return "https://"+window.location.host+"/";
        }
    }

    static getBaseURL() {
        //return 'php/'; //Damit wird im Testsystem auf das Test-PHP-Verzeichnis gegangen
        if(window.location.href.includes("localhost:3000") || window.location.href.includes("peter-thinkpad:3000")) {
            return 'php/';
        } else {
            return "https://"+window.location.host+"/php/";  //Damit wird auch im Testsystem auf das Prod-PHP-Verzeichnis gegangen
        }
    }

    static getDataprotectionURL() {
        return this.getBaseURL() + 'help/dataprotection.php';
    }

    static getAGBURL() {
        return this.getBaseURL() + 'help/agb.php';
    }

    static getImpressumURL() {
        return this.getBaseURL() + 'help/impressum.php';
    }

    static getLoginURL() {
        return this.getBaseURL() + 'account/login.php';
    }

    static getLogoutURL() {
        return this.getBaseURL() + 'account/logout.php';
    }

    static getLoginWithTwitterURL() {
        return this.getBaseURL() + 'twitter/login.php';
    }

    static getLoginWithGoogleURL() {
        return this.getBaseURL() + 'google/login.php';
    }

    static getLoginWithFacebookURL() {
        return this.getBaseURL() + 'facebook/login.php';
    }
    static getLoginWithVIDISURL() {
        return this.getBaseURL() + 'vidis/login.php';
    }
    static getLoginWithEdulogURL() {
        return this.getBaseURL() + 'edulog/login.php';
    }
    static getLoginWithFobizzURL() {
        return this.getBaseURL() + 'fobizz/login.php';
    }
    static getCreateTempAccountURL() {
        return this.getBaseURL() + 'account/createtempaccount.php';
    }

    static getCreateAccountURL() {
        return this.getBaseURL() + 'account/createaccount.php';
    }

    static getBookingRightsURL() {
        return this.getBaseURL() + 'account/bookingrights.php';
    }

    static getAutocompleteURL() {
        return this.getBaseURL() + 'search/autocomplete.php';
    }

    static getRecommendedRessourcesURL() {
        return this.getBaseURL() + 'account/recommendedresources.php';
    }

    static getVisibleResourceURL() {
        return this.getBaseURL() + 'account/visibleresource.php';
    }

    static getSaveSettingsURL() {
        return this.getBaseURL() + 'account/saveusersettings.php';
    }

    static getDeleteUserURL() {
        return this.getBaseURL() + 'account/deleteprofile.php';
    }

    static getSearchURL() {
        return this.getBaseURL() + 'search/search.php';
    }

    static getSearchResultCntURL() {
        return this.getBaseURL() + 'search/searchresultcnt.php';
    }

    static getEventDetailsURL() {
        return this.getBaseURL() + 'details/eventdetails.php';
    }

    static getSentRememberanceURL() {
        return this.getBaseURL() + "notification/sendremembrance.php"
    }

    static getSaveURL() {
        return this.getBaseURL() + 'transactiondata/savedata.php';
    }

    static getSaveBase46ImageURL() {
        return this.getBaseURL() + 'image/savebase64image.php';
    }

    static getRequestNewPasswordURL() {
        return this.getBaseURL() + 'account/requestnewpassword.php';
    }

    static getChangePasswordURL() {
        return this.getBaseURL() + 'account/changepassword.php';
    }

    static getLoadDataURL() {
        return this.getBaseURL() + 'transactiondata/readdata.php';
    }

    static getMailURL() {
        return this.getBaseURL() + 'notification/getmail.php';
    }

    static getProfileURL() {
        return this.getBaseURL() + 'account/getprofile.php';
    }

    static setProfileURL() {
        return this.getBaseURL() + 'account/setprofile.php';
    }

    static getExportURL() {
        return this.getBaseURL() + 'export/export.php';
    }

    static getUploadImageURL() {
        return this.getBaseURL() + 'image/upload.php';
    }

    static getUploadStoryURL() {
        return this.getBaseURL() + 'export/import.php';
    }

    static getWikiImageURL() {
        return this.getBaseURL() + 'image/getwikiimage.php';
    }

    static getCopyBookingRightsURL() {
        return this.getBaseURL() + 'account/copybookingrights.php';
    }

    static getLinksByLinktypeURL() {
        return this.getBaseURL() + 'details/linksbylinktype.php';
    }

    static getSaveBookmarkURL() {
        return this.getBaseURL() + 'bookmarks/savebookmark.php';
    }

    static getDeleteBookmarkURL() {
        return this.getBaseURL() + 'bookmarks/deletebookmark.php';
    }

    static getBookmarksURL() {
        return this.getBaseURL() + 'bookmarks/bookmarks.php';
    }

    static getSaveRatingURL() {
        return this.getBaseURL() + 'rating/saverating.php';
    }

    static getDeleteRatingURL() {
        return this.getBaseURL() + 'rating/deleterating.php';
    }

    static getRatingsURL() {
        return this.getBaseURL() + 'rating/ratings.php';
    }

    static getWikiInfoURL() {
        return this.getBaseURL() + 'network/getwikiinfo.php';
    }

    static getChangeBarGroupNameURL() {
        return this.getBaseURL() + 'transactiondata/changebargroupname.php';
    }

    static getAdvertisingURL() {
        return this.getBaseURL() + 'advertising/advertising.php';
    }

    static getHeaderBackgroundURL() {
        return this.getBaseURL() + 'images/header_back.jpg';
    }

    static getErrorColor() {
        return "red";
    }

    static getNeutralColor() {
        return "#0067b0";
    }

    static getPositiveColor() {
        return "green";
    }

    static getWarningColor() {
        return "#e68a00";
    }

    static getBookingNotEditableColor() {
        return "#CCCCCC";
    }

    static getBookingEditableByResAdminColor() {
        return "#c6d8ec";
    }

    static getBookingEditableByMeColor() {
        return "#3F51B5";
    }

    /**
     * Liefert die zusammengesetzte Zeichenkette für den Namen.
     * Bei Änderungen muss auch helper.php geändert werden
     * @param forename
     * @param surname
     * @param company
     * @returns {*|string}
     */
    static getNameString(forename, surname, company) {
        forename = forename || "";
        surname = surname || "";
        company = company || "";
        let name = forename;

        if (name.length > 0 && surname.length > 0) {
            name += " " + surname;
        } else {
            name += surname;
        }
        if (name.length > 0 && company.length > 0) {
            name += ", " + company;
        } else {
            name += company;
        }
        return name;
    }
}

export default BaseConfig;
