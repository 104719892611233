import React, {useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Paper from '@material-ui/core/Paper';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddStoryDescriptorEditDialog from "./addstorydescriptoreditdialog";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MyButton from "../../../../ui-components/buttons/text/mybutton";
import i18n from "../../../../i18n/i18n";

const parseAddStoryDescriptor = (str) => {
    try {
        let parsedAddStoryDescriptor = JSON.parse(str);
        if (!parsedAddStoryDescriptor || !parsedAddStoryDescriptor.embeddedStories) {
            parsedAddStoryDescriptor = {embeddedStories: []};
        }
        return parsedAddStoryDescriptor;
    } catch(e) {
        return {embeddedStories: []};
    }
}

const deleteItem = (i, parsedAddStoryDescriptor, onChange) => {
    try {
        if(parsedAddStoryDescriptor && parsedAddStoryDescriptor.embeddedStories ) {
            parsedAddStoryDescriptor.embeddedStories.splice(i, 1);
            onChange(parsedAddStoryDescriptor);
        }
    } catch (e) {
        console.log(e);
    }
}

const getComponentForItem = (item, i, parsedAddStoryDescriptor, onChange, onEdit) => {
    return <TableRow key={i}>
        <TableCell>
            {item && (item.bargroup || item.storyid)}
        </TableCell>
        <TableCell style={{textAlign: "right"}}><Button onClick={()=>onEdit(i)}><EditIcon/></Button><Button onClick={()=>deleteItem(i, parsedAddStoryDescriptor, onChange)}><DeleteIcon/></Button></TableCell>
    </TableRow>
}

const AddStoryDescriptor = ({addstorydescriptor, onChange}) => {
    const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [editIndex, setEditIndex] = useState(-1);

    const parsedAddStoryDescriptor = parseAddStoryDescriptor(addstorydescriptor);

    const dialogValuesToJSON = (item) => {
        if(editIndex === -2) {
            //Neues Item
            parsedAddStoryDescriptor.embeddedStories.push(item);
        } else {
            parsedAddStoryDescriptor.embeddedStories[editIndex] = item;
        }
        setEditIndex(-1);
        onChange(JSON.stringify(parsedAddStoryDescriptor, null, 2));
    }

    return <div>
            <Accordion expanded={open} onChange={() => {
                setOpen(!open)
            }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <BrandingWatermarkIcon style={{marginRight: 5,  color: "green"}}/>
                        <Typography variant="subtitle1" style={{marginRight: 10}}>
                            {i18n("addstorydescriptor.js556883219")}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                         <Tabs
                            value={selectedTab}
                            onChange={(event, newValue) => setSelectedTab(newValue)}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="secondary"
                            textColor="secondary"
                        >
                            <Tab value={0}
                                 label={i18n("addstorydescriptor.js92624992")}/>
                            <Tab value={1}
                                 label="JSON"/>
                        </Tabs>
                        <Paper style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}  elevation={5}>
                        {selectedTab === 0 && <div style={{margin: 10}}>
                            <MyButton icon={<BrandingWatermarkIcon style={{marginRight: 10, color: "#FF000"}}/>} onClick={() => setEditIndex(-2)} style={{marginBottom: 10}}>
                                {i18n("addstorydescriptor.js110462036")}
                            </MyButton>
                            <Table style={{width: "100%"}}>
                                <TableBody>
                                    {parsedAddStoryDescriptor && parsedAddStoryDescriptor.embeddedStories && parsedAddStoryDescriptor.embeddedStories.map((item, i) => {
                                        return getComponentForItem(item, i, parsedAddStoryDescriptor, (editedParsedAddStoryDescriptor)=>onChange(JSON.stringify(editedParsedAddStoryDescriptor)), ()=>setEditIndex(i))
                                    })}
                                </TableBody>
                            </Table>
                        </div>}
                        {selectedTab === 1 && <div style={{margin: 10}}>
                            <TextField fullWidth
                                       multiline
                                       value={addstorydescriptor || ""}
                                       style={{flexGrow: 10}}
                                       onChange={(evt) => onChange(evt.target.value)}
                            />
                        </div>}
                    </Paper>
                </AccordionDetails>
            </Accordion>
        {editIndex!==-1 && <AddStoryDescriptorEditDialog onClose={()=>setEditIndex(-1)} onOK={(item)=>dialogValuesToJSON(item)} item={editIndex>=0 && parsedAddStoryDescriptor.embeddedStories[editIndex]}/>}
        </div>
}

export default AddStoryDescriptor;
