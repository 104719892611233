import React from 'react';
import Typography from '@material-ui/core/Typography';
import Config from '../config/config.js';
import Paper from "@material-ui/core/Paper";

const DataImage = (props) => {
    if (props.data) {
        const id = props.data.proxyID || props.data.id;
        const isMaxWidth = props.isMaxWidth;
        const imgURL = getImageURL(props.data, props.model);
        const img = isMaxWidth ? <img src={imgURL} style={{width: window.innerWidth, display: "block"}} alt="Resource"/>
              : <img src={imgURL} style={{maxHeight: 350, maxWidth: '100%', display: "block"}} alt="Resource"/>

        if (props.data.imgSrc && props.data.imgSrc !== "") {
            return img
        } else if (id < 0) {
            if (imgURL) {
                return <a href={"https://commons.wikimedia.org/wiki/File:" + props.data.wikiimg}
                                   rel="nofollow noopener noreferrer"
                                   target="_blank">
                                  {img}
                                </a>
            }
        }
    }
    return null;
}

export const getImageURL = (data, model) => {
  if (data) {
    const id = data.proxyID || data.id;
    if (data.imgSrc && data.imgSrc !== "") {
      return Config.getBaseURL() + "images/resourceimages/" + data.imgSrc;
    } else if (id < 0) {
      let ico = model.getIcon(data);
      if (ico) {
        return ico.src;
      }
    }
    return null;
  }
}



export default DataImage;
