import React, {useEffect, useState} from 'react';
import 'whatwg-fetch';
import IconButton from '@material-ui/core/IconButton';
import TransferHandler from "../transfer/transferhandler.js";
import Config from "../config/config.js";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import {histomania_login_user_is_dummy} from '../histomaniamain';

import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "../i18n/i18n";

const Ratings = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [rating, setRating] = useState(-1);
    const [positiveRatings, setPositiveRatings] = useState(0);
    const [negativeRatings, setNegativeRatings] = useState(0);

    const requestRatings = (showLoadingState) => {
        setIsLoading(showLoadingState);
        //Laden der vorhandenen Ratings
        const successFunc = (json) => {
            setPositiveRatings(json.positivecnt);
            setNegativeRatings(json.negativecnt);
            setRating(json.myrating);
            setIsLoading(false);
        }
        const errorFunc = (code, json) => {
            console.log("Fehler "+code+" "+json);
        }
        TransferHandler.request(Config.getRatingsURL(), successFunc,
                errorFunc, {resid: props.resid});
    };

    useEffect(()=>requestRatings(true), [props.resid]);

    const saveRating = (rating) => {
        const transferObj = {
            resid: props.resid,
            rating
        }
        const successFunc = (json) => {
            requestRatings(false);
        }
        const errorFunc = (code, json) => {
            console.log("Fehler "+code+" "+json);
        }
        if(histomania_login_user_is_dummy) {
            setSnackOpen(true);
        } else {
            TransferHandler.request(Config.getSaveRatingURL(), successFunc,
                errorFunc, transferObj);
        }
    }

        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{color: "black", whiteSpace: "nowrap"}}>
                    <IconButton onClick={() => saveRating(10)} color="secondary">
                        {!isLoading && rating >5 ? <ThumbUpIcon fontSize="small"/> : <ThumbUpOutlinedIcon fontSize="small"/>}
                    </IconButton>
                    {!isLoading ? positiveRatings : "-"}
                    <IconButton onClick={() => saveRating(0)} color="secondary">
                        {!isLoading && rating >= 0 && rating <=5 ? <ThumbDownIcon fontSize="small"/> : <ThumbDownOutlinedIcon fontSize="small"/>}
                    </IconButton>
                    {!isLoading ? negativeRatings : "-"}
                </div>

                <Snackbar
                    open={isSnackOpen}
                    message={<span>{i18n("ratings.js661212453")}</span>}
                    autoHideDuration={4000}
                    onClose={() => setSnackOpen(false)}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => setSnackOpen(false)}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </div>
        )

}

export default Ratings;
