import React, {useState} from "react";
import NewStoryHint from "./blocks/newstoryhint";
import {
  histomania_login_user_is_dummy
} from "../../histomaniamain";
import DummyUserHint from "./blocks/dummyuserhint";
import HeadlineInput from "./blocks/headlineinput";
import LanguageInput from "./blocks/languageinput";
import ConverterUtils from "../../drafteditor/utils/ConverterUtils";
import DescriptionInput from "./blocks/descriptioninput";
import BackgroundImageInput from "./blocks/backgroundimageinput";
import WikipediaInput from "./blocks/wikipediainput";
import shortenWikiURL from "../../wiki/wikiurlshortener";
import SourceReferenceInput from "./blocks/sourcereferenceinput";
import BookingRightsAdministration from "./blocks/bookingrightsadministration";

const COL1 = '#F6F6F6';
const COL2 = '#FFFFFF';

const EditStoryDetails = ({data, model, innerWidth, onHighlight, onFilterClicked, onToggleResourceVisibility, onShowMap, pendingResourceIDs, setDataCollector, onLogonClicked}) => {
  const [language, setLanguage] = useState(data && data.language);
  const [name, setName] = useState(data && data.name);
  const [secname, setSecname] = useState(data && data.secname);
  const [descriptionEditorState, setDescriptionEditorState] = useState(ConverterUtils.toEditorStateFromObject(data, 'description', true));
  const [sourcereferenceEditorState, setSourcereferenceEditorState] = useState(ConverterUtils.toEditorStateFromObject(data, 'sourcereference', true));
  const [imageLicenseEditorState, setImageLicenseEditorState] = useState(ConverterUtils.toEditorStateFromObject(data, 'imagelicense', true));
  const [generatedFilename, setGeneratedFilename] = useState((data && data.imgSrc) || "");
  const [origImageFilename, setOrigImageFilename] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [wikipage, setWikipage] = useState((data && data.wikipage) || "");
  const [addstorydescriptor, setAddstorydescriptor] = useState((data && data.addstorydescriptor) || "");
  const [decorationDescriptor, setDecorationDescriptor] = useState((data && data.decorationdescriptor) || "");

  const [isPrivate, setPrivate] = useState(data && data.isPrivateB());
  const [members, setMembers] = useState(data && data.members);

  const mapDescriptor = data && data.mapDescriptor;

  const collectData = () => {
    let newData = data.clone();
    newData.language = language;
    newData.name = name;
    newData.secname = secname;
    ConverterUtils.editorStateToObject(newData, 'description', descriptionEditorState);
    ConverterUtils.editorStateToObject(newData, 'sourcereference', sourcereferenceEditorState);
    ConverterUtils.editorStateToObject(newData, 'imagelicense', imageLicenseEditorState);
    if(generatedFilename === "") {
      newData.imgName = origImageFilename;
      newData.imageBase64 = imageBase64;
    } else {
      newData.imgName = null;
      newData.imageBase64 = null;
    }
    newData.setWikiPage(shortenWikiURL(wikipage));
    newData.addstorydescriptor = addstorydescriptor;
    newData.decorationdescriptor = decorationDescriptor;

    newData.setMembers(members);
    newData.setPrivateB(isPrivate);
    return newData;
  }

  const onFileChange = ({origImageFilename, imageBase64, generatedFilename, newImageLicenseEditorState}) => {
    setOrigImageFilename(origImageFilename);
    setImageBase64(imageBase64);
    setGeneratedFilename(generatedFilename);
    setImageLicenseEditorState(newImageLicenseEditorState);
  }

  setDataCollector(collectData);
  return <div>
    {data.id ===0 && <NewStoryHint innerWidth={innerWidth} bgColor={COL1}/>}
    {histomania_login_user_is_dummy && <DummyUserHint onLogonClicked={onLogonClicked} innerWidth={innerWidth} bgColor={COL1}/>}
    <LanguageInput language={language} onLanguageChanged={setLanguage} innerWidth={innerWidth} bgColor={COL2}/>
    <HeadlineInput name={name} secname={secname} onNameChanged={setName} onSecnameChanged={setSecname} innerWidth={innerWidth} bgColor={COL1}/>
    <DescriptionInput descriptionEditorState={descriptionEditorState} onDescriptionEditorStateChanged={setDescriptionEditorState} model={model} onHighlight={onHighlight} onFilterClicked={onFilterClicked} onToggleResourceVisibility={onToggleResourceVisibility} onShowMap={onShowMap} pendingResourceIDs={pendingResourceIDs} mapDescriptor={mapDescriptor} innerWidth={innerWidth} bgColor={COL2}/>
    <BackgroundImageInput generatedFilename={generatedFilename} origImageFilename={origImageFilename} imageBase64={imageBase64} imageLicenseEditorState={imageLicenseEditorState} model={model} onHighlight={onHighlight} onFilterClicked={onFilterClicked} onToggleResourceVisibility={onToggleResourceVisibility}  onShowMap={onShowMap} onFileChange={onFileChange} onImageLicenceEditorStateChange={setImageLicenseEditorState} pendingResourceIDs={pendingResourceIDs} mapDescriptor={mapDescriptor} innerWidth={innerWidth} bgColor={COL1}/>
    <WikipediaInput wikipage={wikipage} setWikipage={setWikipage} addstorydescriptor={addstorydescriptor} setAddstorydescriptor={setAddstorydescriptor} decorationDescriptor={decorationDescriptor} setDecorationDescriptor={setDecorationDescriptor}  innerWidth={innerWidth} bgColor={COL2}/>
    <SourceReferenceInput sourcereferenceEditorState={sourcereferenceEditorState} onSourceReferenceEditorStateChanged={setSourcereferenceEditorState} model={model} onHighlight={onHighlight} onFilterClicked={onFilterClicked} onToggleResourceVisibility={onToggleResourceVisibility} onShowMap={onShowMap} pendingResourceIDs={pendingResourceIDs} mapDescriptor={mapDescriptor} innerWidth={innerWidth} bgColor={COL1}/>
    <BookingRightsAdministration resid={data && data.id} isPrivate={isPrivate} onPrivateChaged={setPrivate} members={members} onMembersChanged={setMembers} innerWidth={innerWidth} bgColor={COL2}/>
  </div>
}

export default EditStoryDetails;
