import React from "react";
import createToolbarButton from "../../createToolbarButton";
import ImageIcon from '@material-ui/icons/Image';

let defaultItem = {
  "type": "Image",
  "src": "https://"+window.location.host+"/images/landingpage2.webp",
  "title": "Mein Bild"
}

const Button = createToolbarButton({children: <ImageIcon/>});

const AddImageButton = ({onClick, theme}) => (
  <Button onClick={(evt)=>onClick(evt, defaultItem)} theme={theme}/>
)

export default AddImageButton;
