import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";

const getNewItem = (src, marginLeft, marginRight) => {
    let item = {
        "type": "Markdown",
        "marginLeft": marginLeft * 1,
        "marginRight": marginRight * 1,
        "src": src
    }

    return item;
}

const ChooseMarkdownDialog = ({open, onOK, onCancel, item}) => {
    const [src, setSrc] = useState(item ? item.src : "");
    const [marginLeft, setMarginLeft] = useState(item.marginLeft || 0);
    const [marginRight, setMarginRight] = useState(item.marginRight || 0);

    const cancelClicked = () => {
        setSrc("");
        onCancel();
    }

    const okClicked = () => {
        onOK(getNewItem(src, marginLeft, marginRight));
        setSrc("");
    }

    return <Dialog open={open}
                   onClose={onCancel}
                   fullWidth={true}
    >
        <DialogTitle>Markdown</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <TextField label="Markdown"
                           value={src}
                           onChange={(evt) => setSrc(evt.target.value)}
                           multiline
                           rows={10}
                />
            </div>
            <TextField
                label="linker Abstand"
                value={marginLeft}
                onChange={(evt) => setMarginLeft(evt.target.value)}
                type="number"
            />
            <TextField
                label="rechter Abstand"
                value={marginRight}
                onChange={(evt) => setMarginRight(evt.target.value)}
                type="number"
            />
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={()=>cancelClicked()}/>
            <OKButton onClick={()=>okClicked()}/>
        </DialogActions>
    </Dialog>

}

export default ChooseMarkdownDialog;
