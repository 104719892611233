import React, {useState} from "react";
import DeleteDialog from "./utils/deletedialog";
import Details from "../ui-components/details";
import DeleteButton from "../ui-components/buttons/icon/deleteiconbutton";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveButton from "../ui-components/buttons/icon/saveiconbutton";
import "./styles/storydetails.css";
import DisplayStoryDetails from "./display/displaystorydetails";
import EditStoryDetails from "./edit/editstorydetails";
import MediaFullScreenWrapper from "../media/mediaFullScreenWrapper";
import i18n from "../i18n/i18n";

let myDataCollector;

const StoryDetailsMain = ({
  savingData,
  onToggleResourceVisibility,
  onHighlight,
  onFilterClicked,
  onShowMap,
  onSearch,
  onResShowNeighbourhood,
  onTaskShowDetails,
  onSave,
  onClose,
  onShowSourceReferences,
  data,
  model,
  pendingResourceIDs,
  showWaitOverlay,
  height,
  width,
  innerWidth,
  editMode,
  onDelete,
  showPublish,
  onLogonClicked
}) => {
  const [requestDelete, setRequestDelete] = useState(false);

  const getTitleString = () => {
    return (data && data.id === 0) ? i18n("storydetailsmain.js785302558") : i18n("storydetailsmain.js382589702");
  }

  let buttons;
  //Bei "Was wäre wenn..." darf nicht gespeichert werden
  if (!savingData) {
    if (!(data && data.whatIf)) {
      if (editMode) {
        buttons = <div style={{display: 'flex'}}>
          <SaveButton onClick={() => onSave(myDataCollector())}
                      hideText={window.innerWidth < 700}/>
          {onDelete && <DeleteButton onClick={() => setRequestDelete(true)}/>}
        </div>
      }
    }
  } else {
    buttons = <CircularProgress style={{color: 'white'}}/>
  }

  const publish = () => {
    if(data) {
      let newData = data.clone();
      newData.isPrivate = false;
      onSave(newData);
    }
  }

  return data ? <MediaFullScreenWrapper
          zIndex={1150}
          style={{overflow: 'hidden'}}
          childs={fullScreen => <div style={{height: fullScreen ? window.innerHeight: height}} className={"storydetailsmain"}>
            <Details onClose={() => onClose()}
                     title={getTitleString()}
                     addButtons={buttons}
                     showWaitOverlay={showWaitOverlay}
                     naked={!editMode}
                     width={fullScreen ? window.innerWidth : width}
                     innerWidth={fullScreen ? window.innerWidth : width}
            >
              <div style={{width: "100%"}}>
                {!editMode && <DisplayStoryDetails
                    data={data}
                    model={model}
                    innerWidth={fullScreen ? Math.min(window.innerWidth - 20, 1400) : innerWidth}
                    onPublish={publish}
                    onHighlight={onHighlight}
                    onFilterClicked={onFilterClicked}
                    onToggleResourceVisibility={onToggleResourceVisibility}
                    onTaskShowDetails={onTaskShowDetails}
                    onShowMap={onShowMap}
                    onResShowNeighbourhood={onResShowNeighbourhood}
                    onSearch={onSearch}
                    onShowSourceReferences={onShowSourceReferences}
                    pendingResourceIDs={pendingResourceIDs}
                    showPublish={showPublish}
                />
                }
                {editMode && data.id >= 0 &&
                    <EditStoryDetails
                        setDataCollector={(dataCollector) => myDataCollector = dataCollector}
                        data={data}
                        model={model}
                        innerWidth={fullScreen ? Math.min(window.innerWidth - 20, 1400) : innerWidth}
                        onHighlight={onHighlight}
                        onFilterClicked={onFilterClicked}
                        onToggleResourceVisibility={onToggleResourceVisibility}
                        onShowMap={onShowMap}
                        pendingResourceIDs={pendingResourceIDs}
                        onLogonClicked={onLogonClicked}
                    />
                }
                <DeleteDialog open={requestDelete}
                              onClose={() => setRequestDelete(false)}
                              onDelete={() => {
                                setRequestDelete(false);
                                onDelete()
                              }}/>
              </div>
            </Details>
          </div>
      }/>
      :
      <div></div>
}

export default StoryDetailsMain;
