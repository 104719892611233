import StoryDetailsBlock from "../../display/storydetailsblock";
import Hint from "../../../ui-components/hint";
import React from "react";
import Config from "../../../config/config";
import i18n from "../../../i18n/i18n";

const DummyUserHint = ({onLogonClicked, innerWidth, bgColor}) => {
  return <StoryDetailsBlock width={innerWidth} color={bgColor}>
    <Hint
        type={"warning"}
        header={i18n("dummyuserhint.js499234330")}
        text={<>{i18n("dummyuserhint.js706662830")}<b>Testzwecken</b> erstellen, diese wird jedoch nach einiger Zeit wieder gelöscht.
          <br/>
          Du kannst diese hiStory auch nicht veröffentlichen.
          <br/>
          Die hiStory wird auch nicht in dein Konto übernommen wenn du dich anmeldest.
          <br/>
          Melde dich <span style={{color: Config.getNeutralColor(), cursor: "pointer"}} onClick={onLogonClicked}>hier</span> an.</>}
    />
  </StoryDetailsBlock>
}

export default DummyUserHint;
