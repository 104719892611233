import React from 'react';
import TaskListItem from "./tasklistitem";

const Tasklist = ({tasks, model, onTaskShowDetails, onHighlight, onFilterClicked, onToggleResourceVisibility, onShowMap, pendingResourceIDs, showDetails, filterValue}) => (<React.Fragment>
        {tasks.filter(task => (!filterValue || filterValue.length === 0 || task.name && task.name.toUpperCase().includes(filterValue.toUpperCase())) || (task.secname && task.secname.toUpperCase().includes(filterValue.toUpperCase()))).map((task, index) => (<TaskListItem key={index} t
                                                   task={task}
                                                   model={model}
                                                   onHighlight={()=>onHighlight(task.id)}
                                                   onFilterClicked={onFilterClicked}
                                                   onToggleResourceVisibility={onToggleResourceVisibility}
                                                   onShowMap={onShowMap}
                                                   pendingResourceIDs={pendingResourceIDs}
                                                   onClick={()=>onTaskShowDetails(task)}
                                                   showDetails={showDetails}
            />))
        }
    </React.Fragment>)

export default Tasklist;



