import React from 'react';
import StatusBar2 from './lib/statusbar';
import Config from '../config/config.js';

class StatusBar extends React.Component {
    render() {
        let userImg;
        if (this.props.data.changeUserImg) {
            userImg = Config.getBaseURL() + "images/resourceimages/thumb/" + this.props.data.changeUserImg;
        }

        if (this.props.data.id === 0) {
            return (<div></div>)
        } else {
            return <StatusBar2 avatarSrc={userImg}
                       name={this.props.data.changeuser}
                       time={this.props.data.changetime}
                       displid={this.props.data.proxyID ? this.props.data.proxyID : this.props.data.id}/>
        }

    }
}

export default StatusBar;
