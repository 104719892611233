import React from "react";
import WelcomeBlock from "./welcomeblock";
import TitleList from "../search/lists/titlelist";
import i18n from "../i18n/i18n";
import Arrow from "./arrow";
import ArrowLabel from "./arrowlabel";

/**
 * Der Lehrplan
 */
const Curriculum = ({onToggleResourceVisibility}) => {
        return <div style={{marginTop: 40, display: 'grid', gridTemplateColumns: '60px 60px 20px auto' }}>
            <ArrowLabel col={"#ee0000"} name={i18n("schoolclass6")}/>
            <Arrow col1={"#ee0000"} col2={"#ee7777"}/>
            <div/>
            <TitleList id="schoolclass6" url='schoolclass.php?number=6'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#555555"} name={i18n("schoolclass7")}/>
            <Arrow col1={"#555555"} col2={"#aaaaaa"}/>
            <div/>
            <TitleList id="schoolclass7" url='schoolclass.php?number=7'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#fc922e"} name={i18n("schoolclass8")}/>
            <Arrow col1={"#fc922e"} col2={"#fdae49"}/>
            <div/>
            <TitleList id="schoolclass8" url='schoolclass.php?number=8'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#AABBCC"} name={i18n("schoolclass9")}/>
            <Arrow col1={"#AABBCC"} col2={"#BBCCDD"}/>
            <div/>
            <TitleList id="schoolclass9" url='schoolclass.php?number=9'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#556ed3"} name={i18n("schoolclass10")}/>
            <Arrow col1={"#556ed3"} col2={"#758ee2"}/>
            <div/>
            <TitleList id="schoolclass10" url='schoolclass.php?number=10'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#e6534c"} name={i18n("schoolclass11")}/>
            <Arrow col1={"#e6534c"} col2={"#ed7370"}/>
            <div/>
            <TitleList id="schoolclass11" url='schoolclass.php?number=11'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#955587"} name={i18n("schoolclass12")}/>
            <Arrow col1={"#955587"} col2={"#af75a5"}/>
            <div/>
            <TitleList id="schoolclass12" url='schoolclass.php?number=12'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <ArrowLabel col={"#485776"} name={i18n("schoolclass13")}/>
            <Arrow col1={"#485776"} col2={"#687796"}/>
            <div/>
            <TitleList id="schoolclass13" url='schoolclass.php?number=13'
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
        </div>
}

export default Curriculum;
