import React from "react";
import Typography from '@material-ui/core/Typography';
import i18n from "../../i18n/i18n";
// Title List Item
class MoreItem extends React.Component {
    render() {
        return (
                <div className="MoreItem" onClick={() => this.props && this.props.onClick()}>
                    {this.props.backdrop &&
                    <div className="SmallItem">
                        <div style={{backgroundImage: "url('" + this.props.backdrop + "')"}} className="InnerItem">
                        </div>
                    </div>
                    }

                    <div>
                        <Typography variant={'body1'}>{this.props.preTitle}</Typography>
                        <Typography variant={'subheading'}>{this.props.title}</Typography>
                        <Typography variant={'body1'}>{this.props.overview}</Typography>
                        <div className="ImageLicense">
                            {this.props.imgLicense && this.props.imgLicense !== '' && <div className="ImageLicense"
                                                                                           dangerouslySetInnerHTML={{__html: "<div><a href='https://commons.wikimedia.org/wiki/File:" + this.props.wikiImg + "'rel='nofollow noopener noreferrer' target='_blank'>"+i18n("image")+":</a> " + this.props.imgLicense + "</div>"}}/>}
                        </div>
                    </div>
                </div>

        );
    }
}

export default MoreItem;
