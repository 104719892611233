import * as React from 'react';
import Input from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from "../../images/icons/filter";
import InputAdornment from '@material-ui/core/InputAdornment';

const TextField = ({inputProps, rest, onExtendedSearch, onExtendedFilter, isFiltered}) => {
  return <div style={{ width: '100%' }}>
            <Input
               placeholder=""

                {...inputProps}
                {...rest}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton type="submit"  aria-label="Suche" onClick={onExtendedSearch} size={'small'}>
                      <SearchIcon />
                    </IconButton>
                    {isFiltered && <IconButton type="submit"  aria-label="Suche" onClick={onExtendedFilter} size={'small'}>
                      <FilterIcon color="primary"/>
                    </IconButton>}
                  </InputAdornment>
                }
            />

        </div>;
}

export default TextField;
